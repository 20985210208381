import { delay } from 'q';
import { URLHelper, Utility } from '../models/Helper';
import { axioClient } from './axios'
import { ConfigMockData } from '../mock/ConfigMockData';
import { UIConstants } from '../models/Constants';


export default class ConfigurationAPI {

    static async getConfigurationByKey(key: string) {
        if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
            // return mock data.
            return delay(100).then(() => {
                return ConfigMockData[key] as unknown as Promise<string>;
            });
        } 
        else if(sessionStorage.getItem(key)){
            return sessionStorage.getItem(key) as unknown as Promise<string>;
        }
        else {
            let url = null;
            if (key == UIConstants.Configuration.TenantConfig) url = URLHelper.GetTenantConfig();
            else if (key == UIConstants.Configuration.RolesForTenantConfig) url = URLHelper.GetRolesForTenantConfig();
            else if (key == UIConstants.Configuration.KarnakGroupConfig) url = URLHelper.GetKarnakGroupConfig();
            else if (key == UIConstants.Configuration.EnvironmentConfig) url = URLHelper.GetEnvironmentConfig();
            else if (key == UIConstants.Configuration.MSTRoleGroupConfig) url = URLHelper.GetMSTRoleGroupConfig();
            else if (key == UIConstants.Configuration.FlightConfig) url = URLHelper.GetFlightConfig();
            else if (key == UIConstants.Configuration.VLAppsConfig) url = URLHelper.GetVLAppsConfig();
            else if (key == UIConstants.Configuration.PartnerCenterConfig) url = URLHelper.GetPartnerCenterConfig();
            else if (key == UIConstants.Configuration.KarnakFunctionConfig) url = URLHelper.GetKarnakFunctionConfig();
            else if (key == UIConstants.Configuration.MSSalesConfig) url = URLHelper.GetMSSalesConfig();
            else if (key == UIConstants.Configuration.AllowedDomains) url = URLHelper.GetAllowedDomains();
            else if (key == UIConstants.Configuration.MSApprovalLink) url = URLHelper.GetMSApprovalLink();
            else if (key == UIConstants.Configuration.MercuryConfig) url = URLHelper.GetMercuryConfig();
            else if (key == UIConstants.Configuration.MercuryFAQConfig) url = URLHelper.GetMercuryFAQConfig();
            else if (key == "MSSalesTestConfig") url = URLHelper.GetMSSalesTestConfig();
            else if (key == UIConstants.Configuration.AllowedAccountTypes) url = URLHelper.GetAllowedAccountTypesConfig();
            else if (key == UIConstants.Configuration.QuizValidation) url = URLHelper.GetQuizValidationConfig();
            else if (key == UIConstants.Configuration.PlanCastConfig) url = URLHelper.GetPlancastConfig();
            return axioClient.get<string>(url).then(response => {
                let result: string = null;
                if (response.status && response.status === 200) {
                    if (response.data.length > 0) {
                        result = response.data;
                    }
                }
                return result;
            }).catch(err => {
                return null as unknown as Promise<string>;
            });
        }
    }

    static handleCatch(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return error.response.data as unknown as Promise<any>;

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            return null as unknown as Promise<any>;

        }
    }
}