import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
    Text,
    FontWeights,
    ITextStyles,
    mergeStyleSets,
    IStyle,
    FontSizes
} from '@fluentui/react';


// #region   Help Panel Sample
const Styles = mergeStyleSets(
    {
        h1Text: {
            fontWeight: 400,
            fontSize: FontSizes.size16,
            marginBottom: 0,
            msWrapFlow: 'auto',
            padding : 0
        } as IStyle,
        h1TitleText: {
            color: '#666666',
            fontSize: FontSizes.size14,
            fontWeight: FontWeights.bold,
            margin : 0
        } as IStyle,
        colStyles: {
           
            borderColor: 'black',
            borderStyle: 'solid',
            width: '31%',
            display: 'inline-block',
            minHeight: '350px'
        }
    }
);
interface HomeState {
    txtFilter: string;
}
// At runtime, Redux will merge together...
type ApplicationProps = & HomeState// ... state we've requested from the Redux store
    & RouteComponentProps<{}>; // ... plus incoming routing parameters

export default class Home extends React.PureComponent<ApplicationProps> {
    constructor(props: any) {
        super(props);
    }
    /**
   * React Life cycle Events
   */
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public state: HomeState = {
        txtFilter: '',
    }
    /**
     * UI Render
     */
    public render() {
        const descriptionTextStyles: ITextStyles = {
            root: {
                fontWeight: FontWeights.regular,
                fontSize: FontSizes.size16,
                wrapFlow: '1',
                margin: 2,
                // width: '95%',
                marginBottom: 0,
            }
        };
        return <React.Fragment>
            <div id='tbl-overview' style={{ padding: '25px' }}  >
                <table role="presentation">
                    <tbody>
                    <tr style={{ border: 'none'}}  >
                        <td colSpan={3} style={{display: 'inline-block',  width: '98.3%'}}>
                            <h2 aria-level={2} className={Styles.h1Text}>  Unified access management experience to Finance Services and Assets with automated
                                compliance and security checks.​​​​​​​​​​​​​​</h2>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }} >
                        <td className={Styles.colStyles} style={{marginRight : '1%'}} >
                            <h3 aria-level={3} className={Styles.h1TitleText}> What does EUA Onboarding involve?​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                Loosely coupled user access is a critical pre-requisite, this takes about 85 to 90% of effort for services,
                                on-boarding to EUA will take only the final 10 to 15% chunk.
                                Services that are tightly coupled with their user access starting from business logic;
                                data access layer and stored procedures needs to rip them apart first.
                                When ready to on-board, services will take dependency with EUA for real time authorization.
                                                    <br />
                                <br />
                                                    EUA will ensure to provide high availability and appropriate fall back mechanisms.
                                                    Services can either leverage EUA portal for requesting access or initiate requests via EUA APIs.
                                                    As part of on-boarding we will work with Service teams to ingest current permission model, approval
                                                    hierarchies and initial user migration needs.
                                                    </Text>
                        </td>
                        <td className={Styles.colStyles} style={{marginRight : '1%'}} >
                            <h3 aria-level={3} className={Styles.h1TitleText}> Policy based Access Provisioning and Authorization​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                EUA (unlike traditional approach) provides policy dimension to access provisioning workflows, policies can be pre-configured fixed values or fully dynamic, a multi-level approval hierarchy can be a policy, connectors to other finance systems can be a policy, a geo location validation; FTE checks all can be blended as policies within EUA. <br /><br />Compliance and security policies will also seamlessly fit within EUA’s policy model. EUA provides a configurable and extensible Policy Management capability to meet diverse provisioning needs of Finance systems.
                        </Text>
                        </td>
                        <td className={Styles.colStyles}>
                            <h3 aria-level={3} className={Styles.h1TitleText}> Modernizing Access Provisioning:​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                EUA promotes self-serve access provisioning model and discourages requesting access for others (On Behalf Of), EUA challenges the need for dedicated support persona to add/remove users for services (aligning to shift-left strategy). EUA, however provides email invite capability wherein users or team can be invited to EUA Portal to have them request access for a specific service. <br /><br />EUA provides PIM based access to business end users, each provisioned request will have a set expiry that needs to be periodically renewed. EUA firmly recommends and enforces real time authorization via APIs and discourages authorizing from local replicated copies.
                        </Text>
                        </td>
                    </tr>
                    <tr style={{ border: 'none' }} >
                        <td className={Styles.colStyles} style={{marginRight : '1%'}} >
                            <h3 aria-level={3}  className={Styles.h1TitleText}> Secure Finance Data Lake assets:​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                EUA envisions to govern and provision all access to FDL Datasets and Compute resources. EUA will light up security for following scenarios, Data Publisher workflow, Consumer workflows (Certified and Managed Compute), Engineering team access workflow. EUA, at any given point of time, will provide time real reporting on which users and systems have access to FDL eco system. <br /><br />EUA will additionally provide required SOX Compliance and auditing support for FDL. EUA will also provision time-bound (PIM) access to FDL’s resources (which will periodically be renewed through approved policies).
                        </Text>
                        </td>
                        <td className={Styles.colStyles} style={{marginRight : '1%'}} >
                            <h3 aria-level={3} className={Styles.h1TitleText}>  SOX Compliance and audit workflows​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                EUA provides automated access revalidation workflows, additionally, EUA auto expires access based on idle usage policy checks. EUA to integrate with HR eco system to identify employee movements between orgs/teams and to initiate access removal workflows accordingly. <br /><br />EUA will serve as a single source of truth for access reviews and enables audit persona to support periodical auditing needs.
                        </Text>
                        </td>
                        <td className={Styles.colStyles} >
                            <h3 aria-level={3} className={Styles.h1TitleText}> Onboarding to EUA vs AuthZ?:​​​​​​​​​​​​​​</h3>
                            <br />
                            <Text styles={descriptionTextStyles}>
                                We do have a strong partnership with CPE in this integration effort, when services onboard to EUA, they get automatically onboarded to OneAuthZ as well (behind the scenes). <br /><br />The main effort is in readiness exercise of individual services, onboarding to EUA or AuthZ to be seen one and the same, for capabilities provided by OneAuthZ EUA provides a facade. EUA does integrate with many different enterprise offerings to fulfill its provisioning workflows, OneAuthZ is one such key platform.
                        </Text>
                        </td>
                    </tr>
                    </tbody>
                 </table>
            </div>
        </React.Fragment>;
    };
    /**
   * Data Initializer
   */
    private ensureDataFetched() {
    }
}
