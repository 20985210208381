import React from 'react';
import { UserAgentApplication } from 'msal';
import EUAScreenSpinner from '../../components/EUAScreenSpinner';
import ConfigurationAPI from '../api/Configuration.api';
import { UIConstants } from '../models/Constants';
export const msalConfig = {
    authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
    clientId: process.env.REACT_APP_UICLIENTID,
    redirectUri: document.getElementById('root').baseURI,
    navigateToLoginRequestUrl: true
};
export const msalAuth = new UserAgentApplication({
    auth: msalConfig
});
export interface IMSALState {
    isAuthenticated: boolean;
    user: any;
    hasError: boolean;
    errorMessage: any;
    isExternalUser: boolean;
}
export function withAuth(HocComponent) {
    return class extends React.Component<{}, IMSALState> {
        constructor(props) {
            super(props);

            this.state = {
                isAuthenticated: false,
                user: {},
                hasError: false,
                errorMessage: null,
                isExternalUser: false
            };
        }

        async componentDidMount() {
            let internalUsers = [];
            ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.AllowedDomains).then(response => {
                internalUsers = response?.split(";");
                response && msalAuth.handleRedirectCallback(() => {
                    let userAccount = msalAuth.getAccount();

                    this.setState({
                        isAuthenticated: true,
                        user: userAccount
                    });
                    let urlvalue = sessionStorage.getItem("url");
                    sessionStorage.removeItem("url");
                    if (urlvalue != null && urlvalue != window.location.href) {
                        window.location.href = urlvalue;
                    }
                }, (authErr, accountState) => {  // on fail
                    console.log(authErr);

                    this.setState({
                        hasError: true,
                        errorMessage: authErr.errorMessage
                    });
                });
                let userAccount = msalAuth.getAccount();

                if (!userAccount) {
                    let isInProgress = msalAuth.getLoginInProgress();
                    if (isInProgress) {
                        console.log('Sign in already in Progress ');
                    } else {
                        console.log("Redirecting...")
                        sessionStorage.setItem("url", window.location.href);
                        msalAuth.loginRedirect({});
                        return;
                    }
                } else {
                    this.setState({
                        isAuthenticated: true,
                        user: userAccount,
                        isExternalUser: !(internalUsers?.find(x => userAccount?.userName?.includes(x))!=null)
                    });
                }
            })
        }

        onSignIn() {
            msalAuth.loginRedirect({});
        }

        onSignOut() {
            msalAuth.logout();
        }

        render() {
            const { isAuthenticated, hasError } = this.state;
            if (isAuthenticated) {
                return <HocComponent auth={this.state} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut()} {...this.props} />;
            }

            if (hasError) {
                return <div>{this.state.errorMessage}</div>;
            }

            return <EUAScreenSpinner message={'Signing In'}></EUAScreenSpinner>;
        }
    };
}
