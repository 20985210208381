import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TextField, DetailsListLayoutMode, SelectionMode, Label, Link, ShimmeredDetailsList, mergeStyles, IColumn, Fabric, Dropdown, IDropdownOption, Spinner, SpinnerSize, PrimaryButton, Icon, DefaultButton } from '@fluentui/react';
import { Role, RoleResponse } from "../../shared/models/Role.model"
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import AssetViewDetails from './AssetViewDetails';
import JSONToCSVConvertor from '../../shared/functions/ExportToCSV';
import { FDLReportFilter } from '../../shared/models/UserAccess.model';

let EnvironmentOptions: IDropdownOption[] = [];

export interface IAssetState {
    userDetails: IUser;
    RoleDetails: RoleResponse;
    selectedRole: Role;
    showModal: boolean;
    items: any;
    filteredItems: any;
    approvers: any;
    selectedEnvValue: string;
    assetName: string;
    publisherServiceTreeName: string;
    tenantId: string
    tenantData: ITenant;
    dataContractLinks: any;
}
type AssetProps = IAssetState &
    RouteComponentProps<{}>;
export type AssetGridDetails = {
    description: string,
    assetName: string,
    entityName: string,
    assetId: string,
    serviceTreeName: string,
    attributes: any,
    permissions: any,
    scopes: any,
    sortOrder: string,
    publisherName: string,
    registrationDate: string,
    approvers: any,
    entityGroup:any
}
export type AssetCSV = {
    AssetName: string,
    EntityName: string,
    Description: string,
    DataOwners: string,
    PublisherPrivacyLeads: string,
    PublisherName: string,
    PublisherRequestId: string,
    PublisherServicetreeId: string,
    PublisherServiceTreeName: string,
    OpsEngAlias: string,
    DevEngAlias: string,
    IsSOX: string,
    IsCplusAI: string,
    AccountIdType: string,
    AccountId: string,
    DataContractLinks: string,
    HasClassifiedData: string,
    HasPersonalData: string,
    RegisteredDate: string,
    PpeRequestId: string,
    DataClassificationLevel: string,
    SchemaName: string,
    DataSourceName: string,
    IsDataOwnerApprovalRequired: string,
    PersonalDataField: string,
    AssetId: string,
    EntityGroup: any
}
export default class AssetView extends React.Component<AssetProps> {
    private tenantData: ITenant = null;

    public state: IAssetState = {
        userDetails: null,
        RoleDetails: { Data: null, IsSuccess: true, IsDataLoaded: true },
        items: [],
        filteredItems: [],
        selectedRole: null,
        showModal: false,
        approvers: null,
        selectedEnvValue: '',
        tenantData: null,
        assetName: '',
        publisherServiceTreeName: '',
        tenantId: '',
        dataContractLinks:[]
    };
    public constructor(props) {
        super(props);
    }

    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        tenantName = tenantName.toUpperCase();
        setTimeout(() => {
            let TenantData = Utility.GetTenantData();
            this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            EnvironmentOptions = Utility.getParsedFDLEnvironmentConfig();
            this.setState({ selectedEnvValue: EnvironmentOptions[0].text, userDetails: user, tenantData: this.tenantData, tenantId: EnvironmentOptions[0].key.toString() });
            this.getDetails(EnvironmentOptions[0].key.toString());
        }, 500);
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { RoleDetails, showModal, selectedRole, dataContractLinks, items, approvers, selectedEnvValue, assetName, publisherServiceTreeName, tenantId } = this.state;
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50 }}></div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg8"}>
                           <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}{UIConstants.PageTitle.AssetView}</h1></Label>
                     </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <PrimaryButton style={{ width: 'max-content', position: 'absolute', marginLeft: window.innerWidth < 400 ? '-15%' : 0 }} title="Export to CSV file" onClick={() => this.getCSVfile(items)} ><Icon iconName={'ExcelLogo'} />&nbsp;Export</PrimaryButton>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg4"} >
                        <Label >{'   '}Asset</Label>
                        <TextField placeholder="Search for Asset" styles={{ fieldGroup: [{ height: 40 }] }} value={assetName} onChange={this._onChangeAssetName} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg4"} >
                        <Label >{'   '}Publisher Service Tree Name</Label>
                        <TextField placeholder="Search for Service Tree Name" styles={{ fieldGroup: [{ height: 40 }] }} value={publisherServiceTreeName} onChange={this._onChangeServiceTreeName} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} >
                        <Dropdown
                            defaultSelectedKey={tenantId}
                            selectedKey={tenantId}
                            placeholder={selectedEnvValue}
                            label="Environment"
                            options={EnvironmentOptions}
                            onChange={this.onEnvironmentChange}
                        />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} ></div>
                </div>
                <div key='grid-row-loadData' className={"ms-Grid-row"} style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg12"}>
                    <PrimaryButton title="Search" onClick={() => { this.onSearchClick() }} ><Icon iconName={'Search'} />&nbsp;Search</PrimaryButton>
                        <span>&nbsp;</span>
                        <DefaultButton title="Reset" onClick={() => {
                            this.setState({ assetName: '', publisherServiceTreeName: '' });
                            this.getDetails(EnvironmentOptions[0].key.toString())
                        }} ><Icon iconName={'Reply'} />&nbsp;Reset</DefaultButton>
                    </div>
                </div>
                <div key="work-area" >
                    {(RoleDetails?.IsDataLoaded) ? <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 'fit-content' }}>
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please fill in search filters and click 'Search' button to view the results"}</span>
                            <Label>&nbsp;&nbsp;&nbsp;Total number of Assets: {items.length}</Label>

                            <AssetViewGrid dataLoaded={RoleDetails.IsDataLoaded} items={items}
                                columnCollection={this.getColumnDefintion()}
                            > </AssetViewGrid>
                        </div>
                        {(showModal) && <AssetViewDetails selectedRole={selectedRole} approvers={approvers} isAdminScreen={true} dataContractLinks={dataContractLinks} {...this.props} onDismiss={() => { this.setState({ showModal: false }) }} />}
                    </div> : <><br /><br /> <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></>}
                </div>
            </div>
        </React.Fragment>
    };
    private _onChangeAssetName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ assetName: text });
    };
    private _onChangeServiceTreeName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ publisherServiceTreeName: text });
    };
    onEnvironmentChange = (event, option) => {
        this.setState({ selectedEnvValue: option.text, tenantId: option.key });
        //this.getDetails(option.key);
    }
    onSearchClick() {
        const { tenantId, publisherServiceTreeName, assetName } = this.state
        this.setState({ RoleDetails: null });
        let filter: FDLReportFilter = {
            tenantId: tenantId,
            publisherServiceTreeName: publisherServiceTreeName,
            assetName: assetName
        }
        AccessAPI.getAssetsfromAuthz(filter).then(res => {
            let items: Array<AssetGridDetails> = [];
            res.Data && res.Data.forEach(item => {
                if (item.permissions[0].actions.filter(x => x.includes(UIConstants.RoleDefinition.UIDisplayFlag)).length > 0) {
                    items.push({
                        description: item.description,
                        assetName: item.name,
                        entityName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('entityname') !== -1)[0]?.split(":")[1],
                        serviceTreeName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('servicetreename') !== -1)[0]?.split(":")[1],
                        assetId: item.id,
                        attributes: item.attributes,
                        permissions: item.permissions,
                        scopes: item.scopes,
                        sortOrder: item.sortOrder,
                        approvers: item.permissions[0].actions[0].split("PublisherPrivacyLeads-"),
                        publisherName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('publishername') !== -1)[0]?.split(":")[1],
                        registrationDate: new Date(item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('registereddate') !== -1)[0]?.split(" : ")[1]).toLocaleString().split(",")[0],
                        entityGroup: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('entitygroup') !== -1)[0]?.split(":")[1]
                    })
                }
            });
            let sortedItems = _copyAndSort(items, 'registrationDate', true);
            this.setState({ RoleDetails: res, items: sortedItems, filteredItems: sortedItems });
        });// Call API to Get Role
    }
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const { RoleDetails } = this.state
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        if (column.key === 'status') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.status}`} onClick={() => {
                        this.setState({ showModal: true, selectedRole: RoleDetails.Data.filter(x => x.name === item.assetName), approvers: item.approvers })
                    }} > View Details </Link>
                </div>
            );
        }
        return item[column.key];
    };

    private getCSVfile = (items: any) => {
        let data: Array<AssetCSV> = [];
        items.forEach(item => {
            if (item.permissions[0].actions.filter(x => x.includes(UIConstants.RoleDefinition.UIDisplayFlag)).length > 0) {
                data.push({
                    AssetName: item.assetName,
                    AssetId: item.assetId,
                    EntityName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('entityname') !== -1)[0]?.split(":")[1],
                    Description: item.description,
                    DataOwners: item.permissions[0].actions[0].split("PublisherPrivacyLeads-")[0]?.split("DataOwners-")[1],
                    PublisherPrivacyLeads: item.permissions[0].actions[0].split("PublisherPrivacyLeads-")[1] ?? '',
                    PublisherName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('publishername') !== -1)[0]?.split(":")[1],
                    PublisherRequestId: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('publisherrequestid') !== -1)[0]?.split(":")[1],
                    PublisherServicetreeId: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('servicetreeid') !== -1)[0]?.split(":")[1],
                    PublisherServiceTreeName: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('servicetreename') !== -1)[0]?.split(":")[1],
                    OpsEngAlias: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('opsengalias') !== -1)[0]?.split(":")[1],
                    DevEngAlias: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('devengalias') !== -1)[0]?.split(":")[1],
                    IsSOX: item.permissions[0].actions.filter(x => x.indexOf('IsSOX') !== -1)[0]?.split(":")[1] == ' True' ?"Yes":"No",
                    IsCplusAI: item.permissions[0].actions.filter(x => x.indexOf('IsCplusAI') !== -1)[0]?.split(":")[1] == ' True'  ? "Yes" : "No",
                    DataContractLinks: '=HYPERLINK(' + '\""' + this.state.dataContractLinks[item.permissions[0].actions[2].slice(21)] + '\"", ' + '\""Click here to view Data Contract Links\""' + ')',
                    HasClassifiedData: item.permissions[0].actions.filter(x => x.indexOf('HasClassifiedData') !== -1)[0]?.split(":")[1] == ' True'  ? "Yes" : "No",
                    HasPersonalData: item.permissions[0].actions.filter(x => x.indexOf('HasPersonalData') !== -1)[0]?.split(":")[1] == ' True' ? "Yes" : "No",
                    AccountId: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('accountid') !== -1)[0]?.split(":")[1],
                    AccountIdType: item.permissions[0].actions.filter(x => x.toLowerCase().indexOf('accountidtype') !== -1)[0]?.split(":")[1],
                    RegisteredDate: new Date(item.permissions[0].actions[12]?.slice(17)).toLocaleString().split(",")[0],
                    PpeRequestId: item.permissions[0].actions.filter(x => x.indexOf('PpeRequestId') !== -1)[0]?.split(":")[1] ?? '',
                    DataClassificationLevel: item.permissions[0].actions.filter(x => x.indexOf('DataClassificationLevel') !== -1)[0]?.split(":")[1] ?? '',
                    SchemaName: item.permissions[0].actions.filter(x => x.indexOf('SchemaName') !== -1)[0]?.split(":")[1] ?? '',
                    DataSourceName: item.permissions[0].actions.filter(x => x.indexOf('DataSourceName') !== -1)[0]?.split(":")[1] ?? '',
                    IsDataOwnerApprovalRequired: item.permissions[0].actions.filter(x => x.indexOf('IsDataOwnerApprovalRequired') !== -1)[0]?.split(":")[1] ?? '',
                    PersonalDataField: item.permissions[0].actions.filter(x => x.indexOf('PersonalDataField') !== -1)[0]?.split(":")[1] ?? '',
                    EntityGroup: item.permissions[0].actions.filter(x => x.indexOf('EntityGroup') !== -1)[0]?.split(":")[1] ??''
                })
            }
        });
        return JSONToCSVConvertor(data, "AssetDetails");
    }
    /**
     * Initial onInit function - first entry point
     */
    private getDetails = (TenantId: string) => {
        let dataContractLinks = [];
        this.setState({ selectedEnvValue: EnvironmentOptions[0].text, tenantId: TenantId, RoleDetails:{ Data: null, IsSuccess: true, IsDataLoaded: true }, items:[] });
        AccessAPI.getAccessRequests(TenantId).then(response => {
            response.Data && response.Data.forEach(item => {
                if ((item.requestType == "Add" || item.requestType == "Propagation" || item.requestType == "Migration") && item.role === null && item.mode != "Consumer") {
                    dataContractLinks[item.properties?.publisherRequestId] = item.properties?.dataContractLinks.join(";");
                }
            });
        this.setState({ dataContractLinks: dataContractLinks });
        });// Call API to Get Role
    };

    private getColumnDefintion() {
        return [
            { key: 'entityName', name: 'Asset', fieldName: 'entityName', minWidth: 400, maxWidth: 400, isResizable: false },
            { key: 'publisherName', name: 'Publisher Name', fieldName: 'publisherName', minWidth: 250, maxWidth: 250, isResizable: false },
            { key: 'serviceTreeName', name: 'Publisher Service Tree Name', fieldName: 'serviceTreeName', minWidth: 250, maxWidth: 250, isResizable: false },
            { key: 'registrationDate', name: 'Registration Date', fieldName: 'registrationDate', data: 'number', minWidth: Utility.getDynamicColumnSize(120), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false },
            { key: 'status', name: '', fieldName: 'status', minWidth: 80, maxWidth: 80, isResizable: false, onRender: this._onRenderItemColumn },
        ];
    }
}
interface AssetViewGridProps {
    items,
    dataLoaded,
    columnCollection
}
export const AssetViewGrid: React.FunctionComponent<AssetViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.items, 'user', false);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.items]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <Fabric id="fdl-asset" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
            />
        </Fabric>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'registrationDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey]);
            var d: any = new Date(b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
