import React from 'react'
import { RouteProps, Switch, Route } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { TextField, DetailsListLayoutMode, SelectionMode, Label, Link, ShimmeredDetailsList, DialogType, ProgressIndicator, MessageBarType, MessageBar, mergeStyles, IColumn, Fabric, Dropdown, IDropdownOption, Spinner, SpinnerSize, Icon, getId } from '@fluentui/react';
import { IUser } from '../../shared/models/User.model';
import { DefaultButton, IButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { ModalDialog } from '../ModalDialog';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import CreateReview from './CreateReview';
import ReviewDetails from './ReviewDetails';
import ReviewUserDetails from './ReviewUsersDetails';
import { DialogModel } from '../../shared/models/Dialog.model';
import { ReviewReportFilter, ReviewRequestSubmissionModel, ReviewResponse } from '../../shared/models/UserAccess.model';

const FYOptions: IDropdownOption[] = [
    { key: 'FY23', text: 'FY23' },
    { key: 'FY24', text: 'FY24' }
]

export interface IReviewState {
    userDetails: IUser;
    ReviewResponse: ReviewResponse;
    selectedReview: ReviewRequestSubmissionModel;
    modalDialogContent: any;
    showRemoveReviewConfirmation: boolean,
    showModal: boolean;
    showUserModal: boolean;
    autoHideMessage: boolean;
    items: any;
    filteredItems: any;
    approvers: any;
    selectedFYValue: string;
    message: string;
    tenantId: string;
    hideDialog: boolean;
    showMessage: boolean;
    cancelReview: boolean;
    tenantData: ITenant;
    messageType: MessageBarType;
}
type ReviewProps = IReviewState &
    RouteComponentProps<{}>;
export type ReviewGridDetails = {
    reviewName: string,
    createdBy: string,
    createdDate: string,
    startDate: string,
    endDate: string,
    status: string,
    users: any,
    id:string
}
export default class Review extends React.Component<ReviewProps> {
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private tenantData: ITenant = null;
    private tenantName = '';
    private removeBtnRefList = [];
    private removeBtnRef;
    private removeRequestBtnRef;
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.CancelReview,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.RemoveReviewConfirmationAdmin,
        okAction: null,
        cancelAction: null,
    };
    private alertMsg2CloseBtnRef = React.createRef<IButton>();
    public state: IReviewState = {
        userDetails: null,
        ReviewResponse: { Data: null, IsSuccess: true, IsDataLoaded: true },
        items: [],
        filteredItems: [],
        selectedReview: null,
        showModal: false,
        showMessage: false,
        cancelReview:false,
        showUserModal: false,
        autoHideMessage: true,
        message:'',
        approvers: null,
        selectedFYValue: 'FY24',
        tenantData: null,
        tenantId: '',
        showRemoveReviewConfirmation: true,
        messageType: MessageBarType.info,
        hideDialog: true,
        modalDialogContent: this._modalDialogContent
    };
    
    public constructor(props) {
        super(props);
    }

    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        this.tenantName = (this.props.match.params as any).tenantName;
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === this.tenantName.toLowerCase())[0];
        this.onInit(this.tenantData);
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { ReviewResponse, showMessage, cancelReview, showModal, selectedReview, items, showUserModal, showRemoveReviewConfirmation, selectedFYValue, tenantId, hideDialog } = this.state;
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>              
                {(showMessage) && this.renderMessage()}
                {(cancelReview) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-msg-area'} style={{ height: 50 }}></div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg8"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}{UIConstants.PageTitle.AccessReviewManagement}</h1></Label>
                       
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>    
                    
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"}>                     
                        <Dropdown
                            defaultSelectedKey={tenantId}
                            selectedKey={tenantId}
                            placeholder={selectedFYValue}
                            label="Fiscal Year"
                            options={FYOptions}
                            onChange={this.onFYChange}></Dropdown>
                    </div>
                    <div className="float-right" style={{ paddingRight: 600, paddingTop: 40 }}>
                        <PrimaryButton href={`${UIConstants.RouteConfig.RequestRoot}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.CreateReview}/` + this.tenantName} >{UIConstants.ButtonText.CreateReview} </PrimaryButton>
                    </div>
                    <div key='grid-row-loadData' className={"ms-Grid-row"} style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 20 }}>
                        <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg12"}>                       
                            <PrimaryButton title="Search" onClick={() => { this.onSearchClick() }} ><Icon iconName={'Search'} />&nbsp;Search</PrimaryButton>
                            <span>&nbsp;</span>
                            <DefaultButton title="Reset" onClick={() => {
                                this.setState({ selectedFYValue: '' })
                            }} ><Icon iconName={'Reply'} />&nbsp;Reset</DefaultButton>
                        </div> </div>                        
                
                </div>               
                <div key="work-area" >
                    {(ReviewResponse?.IsDataLoaded) ? <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 'fit-content' }}>
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please fill in search filters and click 'Search' button to view the results"}</span>
                            {/*  <Label>&nbsp;&nbsp;&nbsp;Total number of reviews: {items.length}</Label>*/}
                           
                            <div  style={{ paddingLeft:1500}} >
                                    </div>
                            <ReviewViewGrid dataLoaded={ReviewResponse.IsDataLoaded} items={items}
                                columnCollection={this.getColumnDefintion()}
                            > </ReviewViewGrid>
                            
                        </div>
                        {(showModal) && <ReviewDetails selectedReview={selectedReview} isAdminScreen={true} {...this.props} onDismiss={() => { this.setState({ showModal: false }) }} />}
                        {(showUserModal) && <ReviewUserDetails selectedReview={selectedReview} isAdminScreen={true} {...this.props} onDismiss={() => { this.setState({ showUserModal: false }) }} />}
                    </div> : <><br /><br />
                        <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></>}

                    
                </div>
            </div>
            <div>
                <ModalDialog hidden={hideDialog}
                    onDismiss={this.closeDialog}
                    dialogContentProps={this.state.modalDialogContent}
                    modalProps={{
                        titleAriaId: this._labelId,
                        subtitleAriaId: this._subTextId,
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } },
                    }}
                >
                </ModalDialog>
            </div>
        </React.Fragment>
    };  
    onFYChange = (event, option) => {
        this.setState({ selectedFYValue: option.text });
        //this.getDetails(option.key);
    }
    onSearchClick() {
        const { selectedFYValue } = this.state
        this.setState({ ReviewResponse: null });
        let filter: ReviewReportFilter = {
            tenantId: this.tenantData.TenantId,
            fiscalYear: selectedFYValue
         
        }
        AccessAPI.getAccessReviewreport(filter).then(res => {
            let items: Array<ReviewGridDetails> = [];
            res.Data && res.Data.forEach(item => {
              
                items.push({
                        createdBy: item.requestedBy,                     
                        reviewName: item.reviewName,                    
                    createdDate: new Date(item?.createdDate).toLocaleString().split(",")[0],
                    startDate: new Date(item?.startDate).toLocaleString().split(",")[0],
                    endDate: new Date(item?.endDate).toLocaleString().split(",")[0],
                    status: item.status,
                    users: item.users,
                    id:item.id
                    })
            });
            let sortedItems = _copyAndSort(items, 'createdDate', true);
            this.setState({ ReviewResponse: res, items: sortedItems });
        });
    }
   
    onCreateReviewclick() {
        const { url } = this.props.match;
       
        <Switch>
            <Route path={`${url}/${UIConstants.RouteConfig.CreateReview}/:tenantName`} component={CreateReview} />
        </Switch>
    }
    private _onRenderItemColumn = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
        const { ReviewResponse } = this.state
        const itemClass = mergeStyles({
            selectors: {
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
        });
        if (column.key === 'details') {
            return (
                <div className={itemClass}>
                    <Link role="button" aria-label={`${item.details}`} onClick={() => {
                        this.setState({ showModal: true, selectedReview: ReviewResponse.Data.filter(x => x.reviewName === item.reviewName), approvers: item.approvers })
                    }} > View Details </Link>
                </div>
            );
        }
        if (column.key === 'cancel') {
            return (
                <div hidden={item.status == 'Complete' || item.status == 'Cancelled'}>
                    <Link role="button" componentRef={(componentRef) => {
                        this.removeBtnRefList[index] = componentRef
                    }} aria-label={`${UIConstants.ButtonText.Cancel} ${item.details}`}
                        onClick={() => this.onCancelClick(item)} >{UIConstants.ButtonText.Cancel}</Link>
                </div>

            );
        }
        if (column.key === 'users') {
            let reviewStarted = false;
            if (item.status == 'InProgress' || item.status =='Complete' )
                reviewStarted = true;
            return (
                <div hidden={!reviewStarted} className={itemClass}>
                    <Link role="button" aria-label={`${item.details}`} onClick={() => {
                        this.setState({ showUserModal: true, selectedReview: ReviewResponse.Data.filter(x => x.reviewName === item.reviewName), approvers: item.approvers })
                    }} > View Users </Link>
                </div>
            );
        }
        return item[column.key];
    };

    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds     
        return <div role="alert" aria-label={message} >
            <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsg2CloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                {message}
            </MessageBar>
        </div>
    };
    /**
     * Initial onInit function - first entry point
     */

    private onInit = (tenantData: ITenant) => {
        if (tenantData) {
            this.onSearchClick();
        } else {
            window.location.href = "/";
        }
    };
    private onCancelClick(item) {
       // this.removeBtnRef = currentRowRemoveBtnRef;
        let msgText = UIConstants.Messages.RemoveReviewConfirmationAdmin
            .replace('#ReviewName#', item.reviewName)
        this._modalDialogContent = {
            subText: msgText,
            title: UIConstants.MessageBoxTitle.CancelReview,
            okButtonText: UIConstants.ButtonText.Ok,
            type: DialogType.normal,
            okAction: () => this.cancelReview(item),
            cancelAction: this.closeDialog,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }
        this.setState({ showRemoveConfirmation: true, modalDialogContent: this._modalDialogContent, hideDialog: false });
    }
    private cancelReview(item) {
        if (item) {
            this.setState({ cancelReview: true });
            AccessAPI.CancelReview(item.id).then(async res => {
                if (res.status && res.status === 200) {              
                    this.setState({
                        hideDialog: true, messageType: MessageBarType.success,
                        message: UIConstants.Messages.CancelReviewSuccess,
                        showMessage: true, cancelReview: false
                    });
                    this.onSearchClick();
                } else if ((res.status && res.status !== 200) || res.message) {
                        this.setState({
                            hideDialog: true, cancelReview: false,
                            messageType: MessageBarType.error, showMessage: true, message: UIConstants.Messages.CancelReviewFailure,
                        });               
                }
            });
        }
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private closeRemoveConfirmation = (): void => {
        if (this.removeBtnRef) {
            this.removeBtnRef.focus();
        } else if (this.removeRequestBtnRef) {
            this.removeRequestBtnRef.focus();
        }

    };
    private getColumnDefintion() {
        return [
            { key: 'reviewName', name: 'Review Name', fieldName: 'reviewName', minWidth: 100, maxWidth: 200, isResizable: false },
            { key: 'createdBy', name: 'Created By', fieldName: 'createdBy', minWidth: 100, maxWidth: 200, isResizable: false },
            { key: 'createdDate', name: 'Created Date', fieldName: 'createdDate', data: 'number', minWidth: 100, maxWidth: 150, isResizable: false },
            { key: 'startDate', name: 'Start Date', fieldName: 'startDate', data: 'number', minWidth: 100, maxWidth: 150, isResizable: false },
            { key: 'endDate', name: 'End Date', fieldName: 'endDate', data: 'number', minWidth: 100, maxWidth: 150, isResizable: false },
            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 100, maxWidth: 150, isResizable: false },
            { key: 'details', name: ' ', fieldName: 'Details', minWidth: 100, maxWidth: 150, isResizable: false, onRender: this._onRenderItemColumn },
            { key: 'users', name: ' ', fieldName: 'users', minWidth: 100, maxWidth: 150, isResizable: false, onRender: this._onRenderItemColumn },           
            { key: 'cancel', name: ' ', fieldName: 'Cancel', minWidth: 100, maxWidth: 150, isResizable: false, onRender: this._onRenderItemColumn },

        ];
    }
}


interface ReviewViewGridProps {
    items,
    dataLoaded,
    columnCollection
}
export const ReviewViewGrid: React.FunctionComponent<ReviewViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.items, 'createdDate', true);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.items]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <Fabric id="fdl-asset" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
            />
        </Fabric>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'createdDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey]);
            var d: any = new Date(b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
