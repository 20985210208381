import * as React from 'react';
import { IStyle, mergeStyleSets, Spinner, SpinnerSize } from '@fluentui/react';
import EUALogo from '../assets/EUA_Logo_Transparent.png';
import MSLogo from '../assets/MSLogoTransparent.png';

// #region   Help Panel Sample
const Styles = mergeStyleSets(
    {
        EUALogo: {
            position: 'fixed',
            top: 'calc(50vh - 90px)',//middle - half the height
            left: 'calc(50vw - 90px)',//middle - half the width
            width: 180,
            height: 180,
        } as IStyle,
        MSLogo: {
            position: 'fixed',
            bottom: 36,
            left: 'calc(50vw - 45px)',
            width: 99,
            height: 22
        } as IStyle,
        spinner: {
            position: 'fixed',
            height: 80,
            width: 80,
            left: 'calc(50vw - 40px)',
            top: 'calc(50vh + 150px)',
        } as IStyle,
    }
);

class EUAScreenSpinnerState {
    txtFilter: string;
}
export default class EUAScreenSpinner extends React.PureComponent<{message : string}, EUAScreenSpinnerState> {
    constructor(props: any) {
        super(props);
    }
    /**
   * React Life cycle Events
   */
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public state: EUAScreenSpinnerState = {
        txtFilter: '',
    }
    /**
     * UI Render
     */
    public render() {

        return <React.Fragment>
            <img className={Styles.EUALogo} src={EUALogo}>
            </img>
            <Spinner className={Styles.spinner} size={SpinnerSize.large} label={this.props.message} ariaLive="assertive" labelPosition="bottom" />
            <img className={Styles.MSLogo} src={MSLogo}>
            </img>
        </React.Fragment>;
    };
    /**
   * Data Initializer
   */
    private ensureDataFetched() {
    }
}
