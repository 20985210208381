import React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, MessageBar, IconButton, MessageBarType, IButton } from '@fluentui/react';
import { DialogModel } from '../shared/models/Dialog.model';
import { UIConstants } from '../shared/models/Constants';
export class ModalDialog extends React.Component<
  {
    hidden: boolean;
    onDismiss: any;
    dialogContentProps: DialogModel;
    modalProps: any;
    content?: any;
    showMessage?: boolean,
    message?: string;
  },
  {}
> {
  public state = {
    hideDialog: true
  };
  private alertMsgCloseBtnRef = React.createRef<IButton>();

  public render(): JSX.Element {
    const { dialogContentProps } = this.props;
    return (
      <div>
        <Dialog
          hidden={this.props.hidden}
          onDismiss={() => {
            if (dialogContentProps.okAction) {
              dialogContentProps.cancelAction();
            }
          }}
          dialogContentProps={dialogContentProps}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          {(this.props.showMessage) && this.renderMessage()}
          {this.props.content}
          <DialogFooter>
            <PrimaryButton onClick={() => {
              if (dialogContentProps.okAction) {
                dialogContentProps.okAction();
              }
            }} text={dialogContentProps.okButtonText ? dialogContentProps.okButtonText : UIConstants.ButtonText.Ok} />
            <DefaultButton onClick={() => {
              if (dialogContentProps.cancelAction) {
                dialogContentProps.cancelAction();
              }
            }} text={dialogContentProps.cancelButtonText ? dialogContentProps.cancelButtonText : UIConstants.ButtonText.Cancel} />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
  private renderMessage = () => {
    let { message, dialogContentProps } = this.props;
    return <div role="alert" aria-label={message} >
      <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
        actions={
          <div>
            <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { dialogContentProps.closeMessageAction(); }} />
          </div>
        }
        messageBarType={MessageBarType.warning}
        isMultiline={false}
      >
        <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
      </MessageBar>
    </div>
  };
}
