import React from 'react';
import { Label, MessageBar, MessageBarType, TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import { DefaultButton, IButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { RouteComponentProps } from 'react-router-dom';
import { UIConstants } from '../../shared/models/Constants';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { EUASupplierUser } from '../../shared/models/SupplierUser';

type AddUpdateSupplierUserProps = RouteComponentProps<{}>;
export interface  AddUpdateSupplierUserState {
    message: string;
    messageType: MessageBarType;
    showMessage: boolean;
    selectedSupplierId: string;
    selectedSupplierRequestId: string;
    selectedUserEmail: string;
    isValidSupplierId: string;
    selectedRole: string;
    justfication: string;
    isSupplierRequestId: boolean;
    saveInProgress: boolean;
}

export type GridDetails = {
    user: string;
    supplierId: string;
    supplierRequestId: string;
    role: string;
    createdDate: any;
    isSoftBlocked: string;
    lastAccessedDate: any;
}
export class AddUpdateSupplierUser extends React.Component<AddUpdateSupplierUserProps>  {
  private tenantData: ITenant = null;

  searchBoxInput: React.RefObject<unknown>;
  private alertMsgCloseBtnRef = React.createRef<IButton>();
  constructor(props: any) {
    super(props);
  }
  public state: AddUpdateSupplierUserState = {
    message: '',
    messageType: MessageBarType.info,
    showMessage: false,
    selectedSupplierId: '',
    selectedSupplierRequestId: '',
    selectedUserEmail: '',
    isValidSupplierId: '',
    selectedRole: 'Admin',
    justfication: '',
    isSupplierRequestId: false,
    saveInProgress: false
  }
  public async componentDidMount() {
    let tenantName = (this.props.match.params as any).tenantName;
    this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
  }

  private renderMessage = () => {
    let { message, messageType } = this.state;

    return <div role="alert" aria-label={message} >
      <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
        actions={
          <div>
            <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
          </div>
        }
        messageBarType={messageType}
        isMultiline={false}
      >
        {message}
      </MessageBar>
    </div>
  };



  public render(): JSX.Element {
    const { showMessage, selectedRole, saveInProgress } = this.state;
    return <React.Fragment>
      <div id={'div-msg-area'} style={{ height: 50 }}>
        {(showMessage) && this.renderMessage()}
      </div>
      <div key="grid-request" className={"ms-Grid"} dir="ltr">
        <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
            <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.AddUpdateSupplierUser}</h1></Label>
          </div>
        </div>
        <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"} >
            <Label required={true}>{'   '}SupplierID</Label>
            <TextField placeholder="Enter for Supplier ID" onBlur={this.handleBlur} value={this.state.selectedSupplierId} onChange={this._onChangeSupplierId} />
            {<div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{this.state.isValidSupplierId ?? ''}</div>}
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"} >
            <Label required={true}>{'   '}SupplierRequestID</Label>
            <TextField placeholder="Enter for Supplier Request ID" disabled={this.state.isSupplierRequestId} value={this.state.selectedSupplierRequestId} onChange={this._onChangeSupplierRequestId} />
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"} >
            <Label required={true}>User Email</Label>
            <TextField placeholder="Enter for UserEmail" value={this.state.selectedUserEmail} onChange={this._onChangeUserEmail} />
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"}>
            <Label required={true}>Role</Label>
            <TextField disabled={true} value={selectedRole}/>
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"} >
            <Label required={true}>{UIConstants.BusinessJustfication}</Label>
            <TextField placeholder="Enter for Business Justification" value={this.state.justfication} onChange={this._onChangeJustfication} />
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg7"} >
            <PrimaryButton style={{ marginTop: 27 }} onClick={this.onSubmitClick} disabled={saveInProgress == true}>{UIConstants.ButtonText.Submit} </PrimaryButton>
            <span>&nbsp;</span>
            <DefaultButton onClick={this.onResetClick} disabled={saveInProgress == true}> {UIConstants.ButtonText.Reset} </DefaultButton>
          </div>
        </div>
      </div>
      <br />
    </React.Fragment>
  }

  private _onChangeSupplierId = (event): void => {
    this.setState({ selectedSupplierId: event.target.value.trim() })
  };

  private handleBlur = (event): void => {
    let text = event.target.value;
    AccessAPI.getSupplierRequestId(text.trim()).then(res => {
      if (res != null && res.supplierId != null) {
        if (res.supplierRequestId != null) {
          this.setState({
            selectedSupplierId: text.trim(), selectedSupplierRequestId: res.supplierRequestId, isValidSupplierId: '', isSupplierRequestId: true
          });
        }
        else {
          this.setState({
            selectedSupplierId: text.trim(), selectedSupplierRequestId: '', isValidSupplierId: 'For the given supplier ID, supplier request ID is empty in EUA. Please enter a valid Supplier Request ID.', isSupplierRequestId: true
          });
        }
      }
      else {
        this.setState({ selectedSupplierId: text.trim(), selectedSupplierRequestId: '', isValidSupplierId: "You are adding a new supplier ID. Please enter valid supplier request ID.", isSupplierRequestId: false });

      }
    })
  };

  private _onChangeSupplierRequestId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({ selectedSupplierRequestId: text.trim() });
  };
  private _onChangeJustfication = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({ justfication: text });
  };
  private _onChangeUserEmail = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({ selectedUserEmail: text.trim() });
  };

  private onResetClick = (): void => {
    this.setState({ selectedSupplierId: '', selectedSupplierRequestId: '', justfication: '', selectedUserEmail: '', showMessage: false, isValidSupplierId: '', isSupplierRequestId: false });
  };
  private onSubmitClick = async (): Promise<void> => {
    const { selectedSupplierId, selectedSupplierRequestId, justfication, selectedUserEmail, selectedRole, showMessage, isSupplierRequestId, isValidSupplierId, saveInProgress } = this.state;
    let errorMessage = null;
    if (justfication.trim().length === 0) {
      errorMessage = errorMessage == null ? UIConstants.BusinessJustfication : `${errorMessage}, ${UIConstants.BusinessJustfication}`
    }
    if (selectedSupplierId.trim().length === 0) {
      errorMessage = errorMessage == null ? UIConstants.SupplierId : `${errorMessage}, ${UIConstants.SupplierId}`
    }
    if (selectedSupplierRequestId.trim().length === 0) {
      if (isSupplierRequestId && isValidSupplierId == 'For the given supplier ID, supplier request ID is empty in EUA. Please enter a valid Supplier Request ID.')
      {
        errorMessage = null
      }
      else {
        errorMessage = errorMessage == null ? UIConstants.SupplierRequestId : `${errorMessage}, ${UIConstants.SupplierRequestId}`
      }
    }
    if (selectedUserEmail.trim().length === 0) {
      errorMessage = errorMessage == null ? UIConstants.UserEmail : `${errorMessage}, ${UIConstants.UserEmail}`
    }
    

    if (errorMessage == null) {
      let data: EUASupplierUser = {
        UserEmail: selectedUserEmail,
        SupplierId: selectedSupplierId,
        SupplierRequestId: selectedSupplierRequestId,
        Role: selectedRole,
        BusinessJustification: justfication
      };
      this.setState({ saveInProgress: true });
      AccessAPI.addSupplierUser(data).then(async res => {
        if (res.status && res.status === 200) {
          this.setState({
            messageType: MessageBarType.success,
            message: res.data,
            showMessage: true,
            selectedSupplierId: '',
            selectedSupplierRequestId: '',
            justfication: '',
            selectedUserEmail: '',
            isValidSupplierId: '',
            isSupplierRequestId: false,
            saveInProgress: false
          });
        }
        else if ((res.status && res.status !== 200) || res.message) {
          this.setState({
            saveInProgress: false,
            messageType: MessageBarType.warning,
            message: res.status === 403 ? "You do not have the AP Global Admin role required to make this change." : res.message,
            showMessage: true
          });
        }
      });
    }
    else {
      this.setState({ showMessage: true, messageType: MessageBarType.warning, message: ` ${errorMessage} is required.` });
    }
  };

}
