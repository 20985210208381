import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TextField, DetailsListLayoutMode, SelectionMode, Label, Link, ShimmeredDetailsList, mergeStyles, IColumn, Fabric, Dropdown, IDropdownOption, Spinner, SpinnerSize, Icon, PrimaryButton, DefaultButton } from '@fluentui/react';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import _ from "lodash";
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { Asset, ConsumerAssetMappingModel, AssetMappingResponse } from '../../shared/models/ConsumerAssetMap.model';
import { FDLReportFilter } from '../../shared/models/UserAccess.model';
import JSONToCSVConvertor from '../../shared/functions/ExportToCSV';

let EnvironmentOptions: IDropdownOption[] = [];

export interface IAssetState {
    selectedEnvValue: string,
    tenantId: string,
    AssetMapDetails: AssetMappingResponse;
    OriginalAssetMap: any,
    items: any,
    assetDetails: Asset[],
    requestType: string,
    assetName: string,
    publisherServiceTreeName: string,
    consumerServiceTreeId: string,
    consumerRequestId: string,
}
type AssetProps = IAssetState &
    RouteComponentProps<{}>;
export type AssetMapDetails = {
    assetName: string;
    publisherName: string;
    consumerRequestId: string;
    consumerServiceName: string,
    consumerServiceTreeId: string,
    consumerServiceTreeName: string,
    publisherServiceTreeId: string;
    publisherServiceTreeName: string;
    assetDetails: Asset[];
    requestType: string;
    environment: string;
    registeredDate: string;
    securityGroup: string;
    accountIdType: string;
    accountId: string;
    consumerOwner1: string;
    consumerOwner2: string;
    entityGroup: string;
}
export type AssetCSVDetails = {
    AssetName: string;
    PublisherName: string;
    ConsumerRequestId: string;
    ConsumerServiceName: string;
    ConsumerServiceTreeId: string;
    ConsumerServiceTreeName: string;
    RegisteredDate: string;
    PublisherServiceTreeId: string;
    PublisherServiceTreeName: string;
    AccountIdType: string;
    AccountId: string;
    SecurityGroup: string;
    consumerOwner1: string;
    consumerOwner2: string;
    entityGroup: string;
}

export default class ConsumerAssetMappingView extends React.Component<AssetProps> {
    private tenantData: ITenant = null;

    public state: IAssetState = {
        selectedEnvValue: '',
        AssetMapDetails: { IsDataLoaded: true, IsSuccess: true, Data: null },
        OriginalAssetMap: [],
        items: [],
        assetDetails: null,
        requestType: '',
        tenantId: '',
        assetName: '',
        consumerRequestId: '',
        consumerServiceTreeId: '',
        publisherServiceTreeName: ''
    };
    public constructor(props) {
        super(props);
    }

    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        tenantName = tenantName.toUpperCase();
        setTimeout(() => {
            let TenantData = Utility.GetTenantData();
            this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            EnvironmentOptions = Utility.getParsedFDLEnvironmentConfig();
            this.setState({ selectedEnvValue: EnvironmentOptions[0].text, userDetails: user, tenantData: this.tenantData, tenantId: EnvironmentOptions[0].key, AssetMapDetails: { IsDataLoaded: true, IsSuccess: true, Data: null }, items: [], OriginalAssetMap: [] });
        }, 500);
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { selectedEnvValue, AssetMapDetails, tenantId, items, assetName, consumerRequestId, consumerServiceTreeId, publisherServiceTreeName} = this.state;
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 50 }}></div>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                    <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg11"}>
                        <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}{UIConstants.PageTitle.ConsumerAssetMappingView}</h1></Label>
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <PrimaryButton style={{ width: 'max-content', position: 'absolute', marginLeft: window.innerWidth < 400 ? '-15%' : 0 }} title="Export to CSV file" onClick={() => this.getCSVfile(items)} ><Icon iconName={'ExcelLogo'} />&nbsp;Export</PrimaryButton>
                        </div>
                    </div>
                </div>
                <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg2"} >
                        <Label >{'   '}Asset</Label>
                        <TextField placeholder="Enter Asset" styles={{ fieldGroup: [{ height: 40 }] }} value={assetName} onChange={this._onChangeAssetName} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label >{'   '}Publisher Service Tree Name</Label>
                        <TextField placeholder="Enter Publisher Service Tree Name" styles={{ fieldGroup: [{ height: 40 }] }} value={publisherServiceTreeName} onChange={this._onChangePublisherServiceTreeName} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label >{'   '}Consumer Service Tree Id</Label>
                        <TextField placeholder="Enter Consumer Service Tree Id" styles={{ fieldGroup: [{ height: 40 }] }} value={consumerServiceTreeId} onChange={this._onChangeConsumerServiceTreeId} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg3"} >
                        <Label >{'   '}Consumer Request Id</Label>
                        <TextField placeholder="Enter Consumer Request Id" styles={{ fieldGroup: [{ height: 40 }] }} value={consumerRequestId} onChange={this._onChangeConsumerRequestId} />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} >
                        <Dropdown
                            defaultSelectedKey={tenantId}
                            selectedKey={tenantId}
                            placeholder={selectedEnvValue}
                            label="Environment"
                            options={EnvironmentOptions}
                            onChange={this.onEnvironmentChange}
                        />
                    </div>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg1"} ></div>
                </div>
                <div key='grid-row-loadData' className={"ms-Grid-row"} style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 10 }}>
                    <div className={"ms-Grid-col ms-sm6 ms-md4 ms-lg12"}>
                        <PrimaryButton title="Search" onClick={() => { this.onSearchClick() }} ><Icon iconName={'Search'} />&nbsp;Search</PrimaryButton>
                        <span>&nbsp;</span>
                        <DefaultButton title="Reset" onClick={() => {
                            this.setState({ assetName: '', publisherServiceTreeName: '', consumerServiceTreeId: '', consumerRequestId: '', selectedEnvValue: EnvironmentOptions[0].text, tenantId: EnvironmentOptions[0].key, AssetMapDetails: { IsDataLoaded: true, IsSuccess: true, Data: null }, items: [], OriginalAssetMap: [] })
                        }} ><Icon iconName={'Reply'} />&nbsp;Reset</DefaultButton>
                    </div>
                </div>
                    <div key="work-area" >
                    {(AssetMapDetails?.IsDataLoaded) ? <div key='grid-row-grid' id={'div-myaccess'} className={"ms-Grid-row"} >
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 'fit-content' }} >
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please fill in search filters and click 'Search' button to view the results"}</span><br></br>
                            <span style={{ paddingLeft: 10, fontSize: 14, fontWeight: 400 }}>{"Please use exact values for 'Consumer Service Tree Id' and 'Consumer Request Id' search filters."}</span>
                            <Label>&nbsp;&nbsp;&nbsp;Total number of Assets: {items.length}</Label>

                            <AssetViewGrid dataLoaded={AssetMapDetails.IsDataLoaded} items={items}
                                columnCollection={this.getColumnDefintion()}
                            > </AssetViewGrid>
                        </div>
                    </div> : <><br /><br /> <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></>}
                </div>
            </div>
        </React.Fragment>
    };
    private _onChangeAssetName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ assetName: text });
    };
    private _onChangePublisherServiceTreeName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ publisherServiceTreeName: text });
    };
    private _onChangeConsumerServiceTreeId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ consumerServiceTreeId: text });
    };
    private _onChangeConsumerRequestId = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({ consumerRequestId: text });
    };
    onEnvironmentChange = (event, option) => {
        this.setState({ selectedEnvValue: option.text, tenantId: option.key});        
    }
    onSearchClick() {
        const { tenantId, publisherServiceTreeName, consumerRequestId, consumerServiceTreeId, items, assetName } = this.state
        this.setState({ AssetMapDetails: null });
        let filter: FDLReportFilter = {
            tenantId: tenantId,
            consumerServiceTreeId: consumerServiceTreeId
        }
        this.getDetails(filter);
        
    }
     private getCSVfile = (items: any) => {
        let data: Array<AssetCSVDetails> = [];
        items.forEach(item => {
                data.push({
                    AssetName: item?.assetName,
                    ConsumerServiceName: item?.consumerServiceName,
                    ConsumerServiceTreeId: item?.consumerServiceTreeId,
                    ConsumerServiceTreeName: item?.consumerServiceTreeName,
                    ConsumerRequestId: item?.consumerRequestId,
                    PublisherName: item?.publisherName,
                    PublisherServiceTreeId: item?.publisherServiceTreeId,
                    PublisherServiceTreeName: item?.publisherServiceTreeName,
                    AccountIdType: item?.accountIdType,
                    AccountId: item?.accountId,
                    SecurityGroup: item?.securityGroup,
                    RegisteredDate: new Date(item?.registeredDate).toLocaleString().split(",")[0],
                    consumerOwner1: item?.consumerOwners == null ? '' : item?.consumerOwners[0],
                    consumerOwner2: item?.consumerOwners == null ? '' : item?.consumerOwners[1],
                    entityGroup:item?.entityGroup
                })
        });
         return JSONToCSVConvertor(data, "AssetMappingDetails");
    }
    /**
     * Initial onInit function - first entry point
     */
    private getDetails = (filter: FDLReportFilter) => {
        const { tenantId, publisherServiceTreeName, consumerRequestId, consumerServiceTreeId, items, assetName } = this.state
        AccessAPI.getConsumerAssetMappings(filter).then(res => {
            let items: Array<AssetMapDetails> = [];
            res.Data && res.Data.forEach(item => {
                item.Assets.forEach(asset => {
                    items.push({
                        assetName: asset?.EntityName,
                        consumerServiceName: item.ServiceName,
                        publisherName: asset?.PublisherName,
                        consumerServiceTreeId: item?.ServiceTreeId,
                        consumerRequestId: asset?.ConsumerRequestId,
                        publisherServiceTreeName: asset?.PublisherServiceTreeName,
                        publisherServiceTreeId: asset?.PublisherServiceTreeId,
                        registeredDate: new Date(asset?.CompletedDate).toLocaleString().split(",")[0],
                        consumerServiceTreeName: item?.ServiceTreeName,
                        assetDetails: item?.Assets,
                        requestType: item?.RequestType,
                        environment: item?.Environment,
                        accountIdType: asset?.AccountIdType,
                        accountId: asset?.AccountId,
                        securityGroup: item?.SecurityGroup,
                        consumerOwner1: asset?.ConsumerOwners == null ? '' : asset?.ConsumerOwners[0],
                        consumerOwner2: asset?.ConsumerOwners == null ? '' : asset?.ConsumerOwners[1],
                        entityGroup: asset?.EntityGroup
                    })
                });
            });
            let data = consumerRequestId != '' ? items.filter(x => x?.consumerRequestId == consumerRequestId) : items;
            data = assetName != '' ? data.filter(x => x.assetName?.toLowerCase().indexOf(assetName.toLowerCase()) > -1) : data;
            data = publisherServiceTreeName != '' ? data.filter(x => x?.publisherServiceTreeName?.toLowerCase().indexOf(publisherServiceTreeName.toLowerCase()) > -1) : data;
            let sortedItems = _copyAndSort(data, 'registeredDate', true);
            this.setState({ AssetMapDetails: res, items: sortedItems, OriginalAssetMap: sortedItems });
        });
    };

    private getColumnDefintion() {
        return [
            { key: 'assetName', name: 'Asset', fieldName: 'assetName', minWidth: 270, maxWidth: 300, isResizable: false },
            { key: 'consumerServiceName', name: 'Consumer Service Name', fieldName: 'consumerServiceName', minWidth: 160, maxWidth: 160, isResizable: false },            
            { key: 'consumerServiceTreeId', name: 'Consumer Service Tree Id', fieldName: 'consumerServiceTreeId', minWidth: 270, maxWidth: 270, isResizable: false },
            { key: 'consumerServiceTreeName', name: 'Consumer Service Tree Name', fieldName: 'consumerServiceTreeName', minWidth: 190, maxWidth: 200, isResizable: false },
            { key: 'consumerRequestId', name: 'Consumer Request Id', fieldName: 'consumerRequestId', minWidth: 270, maxWidth: 270, isResizable: false },
            { key: 'publisherName', name: 'Publisher Name', fieldName: 'publisherName', minWidth: 120, maxWidth: 140, isResizable: false },
            { key: 'publisherServiceTreeId', name: 'Publisher Service Tree Id', fieldName: 'publisherServiceTreeId', minWidth: 260, maxWidth: 260, isResizable: false },
            { key: 'publisherServiceTreeName', name: 'Publisher Service Tree Name', fieldName: 'publisherServiceTreeName', minWidth: 190, maxWidth: 200, isResizable: false },
            { key: 'accountIdType', name: 'Account Id Type', fieldName: 'accountIdType', minWidth: 110, maxWidth: 110, isResizable: false },
            { key: 'accountId', name: 'Account Id', fieldName: 'accountId', minWidth: 270, maxWidth: 270, isResizable: false },
            { key: 'securityGroup', name: 'Security Group', fieldName: 'securityGroup', minWidth: 100, maxWidth: 200, isResizable: false },
            { key: 'consumerOwner1', name: 'Consumer Owner1', fieldName: 'consumerOwner1', minWidth: 270, maxWidth: 300, isResizable: true },
            { key: 'consumerOwner2', name: 'Consumer Owner2', fieldName: 'consumerOwner2', minWidth: 270, maxWidth: 300, isResizable: true },
            { key: 'entityGroup', name: 'Entity Group', fieldName: 'entityGroup', minWidth: 270, maxWidth: 300, isResizable: true },
            { key: 'registeredDate', name: 'Registered Date', fieldName: 'registeredDate', data: 'number', minWidth: Utility.getDynamicColumnSize(110), maxWidth: Utility.getDynamicColumnSize(120), isResizable: false },
        ];
    }
}
interface AssetViewGridProps {
    items,
    dataLoaded,
    columnCollection
}
export const AssetViewGrid: React.FunctionComponent<AssetViewGridProps> = (props) => {
    const [gridData, setgridData] = React.useState<any>([]);
    const [isGridLoading, setIsGridLoading] = React.useState<boolean>(true);
    const [colCollection, setColCollection] = React.useState<any>([]);

    const fetchData = (): void => {
        setIsGridLoading(true);
        setColCollection(props.columnCollection);
        if (props.dataLoaded) {
            setIsGridLoading(false);
            // Sort the items.
            let sortedItems = _copyAndSort(props.items, 'registeredDate', true);
            setgridData(sortedItems);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.items]);

    const _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        let isSortedDescending = column.isSortedDescending;
        let sortedItems = [];
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(gridData, column.fieldName!, isSortedDescending);
        // Reset the items and columns to match the state.

        setgridData(sortedItems);
        let updatedColumnState = colCollection.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        setColCollection(updatedColumnState);

    };
    return (<React.Fragment>
        <div id="fdl-consumer-asset" style={{
            display: 'block',
            margin: '0 auto',
        }} >
            <ShimmeredDetailsList
                setKey="items"
                columns={props.columnCollection}
                items={gridData}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="User Management List"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                enableShimmer={isGridLoading}
                onColumnHeaderClick={_onColumnClick}
            />
        </div>
    </React.Fragment>
    );
}
function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (columnKey === 'registeredDate') {
        return items.slice(0).sort(function (a: T, b: T) {
            var c: any = new Date(a[columnKey]);
            var d: any = new Date(b[columnKey]);
            return isSortedDescending ? d - c : c - d;
        });

    } else {
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}
