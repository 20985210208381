import React from 'react';
import { Modal, IconButton, Fabric, DetailsList, SelectionMode, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Link, DetailsListLayoutMode, Icon, ActivityItem, Label, Spinner, SpinnerSize, MessageBar, MessageBarType } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import { MyAccessRequestCollection } from '../../shared/models/MyAccessRequest.model';
import AccessAPI from '../../store/AccessAPI';
import { ApproverDetailsResponse } from '../../shared/models/ApproverDetails.model';
import { Utility } from '../../shared/models/Helper';
import _ from 'lodash';
import ITenant from '../../shared/models/Tenant.model';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium
    }, actionText: {
        fontSize: FontSizes.medium
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});

interface IRequestDetailsProps {
    requestId: string;
    requestedBy: string;
    isAdminScreen : boolean;
    data: MyAccessRequestCollection;
    onDismiss: Function;
}
interface IAccessRequestDetailState {
    showModal: boolean,
    AppproverDetails: ApproverDetailsResponse
    requestedByUser: string
}

type MyAccessRequestProps = IRequestDetailsProps;

export default class AccessRequestDetails extends React.Component<MyAccessRequestProps, IAccessRequestDetailState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private sapRequestStatusEndPoint: string;
    private tenantData: ITenant = null;
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: true,
            AppproverDetails: null,
            requestedByUser:''
        };

    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    private ensureDataFetched() {
        const { data, isAdminScreen } = this.props;
        let urlPathName = window.location.pathname
        let urlValues = urlPathName.split('/');
        let tenantName = _.last(urlValues).toUpperCase();
        let TenantData = Utility.GetTenantData();
        this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
        if (data.status === 'InProgress') {
            AccessAPI.getApprovalStatusDetails(this.props.requestId).then(res => {
                this.setState({ AppproverDetails: res }, () => { this.getRequestedByUser() });
            });
        } else {
            if (isAdminScreen) {
                if (this.tenantData?.isExternalAADTenant) {
                    let user = (this.props.data["userId"] === "" || this.props.data["userId"] === null) ? this.props.data["user"] : this.props.data["userId"]
                    AccessAPI.getExternalAADAccessRequest(user, this.props.data['role'], this.tenantData.TenantId, this.props.data['businessGroup']).then(res => {
                        this.setState({ AppproverDetails: res }, () => { this.getRequestedByUser() });
                    });
                }
                else {
                    AccessAPI.GetPolicyInfoFromPolicyServiceByRole(this.props.data['userId'], this.props.data['roleId'], this.tenantData.TenantId).then(res => {
                        this.setState({ AppproverDetails: res }, () => { this.getRequestedByUser() });
                    });
                }
            } else {
                AccessAPI.GetProvisionedRequestStatus(this.props.requestId).then(res => {
                    this.setState({ AppproverDetails: res }, () => { this.getRequestedByUser() });
                });
            }
        }
    }

    private getRequestedByUser =()=> {
        const { requestedBy,data } = this.props;
        const { AppproverDetails } = this.state;
        if (AppproverDetails.Data) {
            let _requestedByUser = this.state.AppproverDetails?.IsDataLoaded && ((requestedBy == null ? AppproverDetails.Data.requestData?.requestedBy?.split("@")[0] : requestedBy.split("@")[0]) ?? data.user);
            this.setState({ requestedByUser: _requestedByUser })
        }
    }

    public render() {
        const { requestId, data, requestedBy } = this.props;
        const { AppproverDetails, requestedByUser } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData , requestData } = Data || {};
        return <React.Fragment>
            <Modal titleAriaId={this._titleId}
                isOpen={this.state.showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12 , marginTop : 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12 , marginTop : 20 }}   ><h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.AccessDetails}</h2>
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div tabIndex={0} style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop : 30
                    }}
                    >
                        <div className={"ms-Grid"} dir="ltr">
                            <div className={"ms-Grid-row"}>
                                <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg6"}>
                                    <label>
                                        <div className={classNames.lblText} >Request Id: {requestId ?
                                            requestId : requestData?.requestId ? requestData?.requestId : migrationData?.id}</div>
                                    </label>
                                    <span className={classNames.lblText} >Request Type: {AppproverDetails?.IsDataLoaded && AppproverDetails?.Data?.requestData?.requestType != null ? AppproverDetails.Data?.requestData?.requestType : AppproverDetails?.IsDataLoaded && data.requestType} {migrationData && ` (${migrationData.businessJustification.replace("Initial Migration - ", "").replace("Initial Migration: ", "").replace("Initial Migration : ", "")})`} {this.tenantData?.TenantName === UIConstants.Tenant.Mercury && (AppproverDetails?.IsDataLoaded && requestedByUser != '') && requestedByUser.split('@')[0] != data.user.split('@')[0] && ` (${UIConstants.Labletext.OnBehalf})`}</span>
                                    {(migrationData == null) && <span className={classNames.lblText} > Requested By: {AppproverDetails?.IsDataLoaded && ((requestedBy == null ? (this.tenantData?.isExternalAADTenant && (this.props.data["userId"] === "" || this.props.data["userId"] === null) ? requestData.requestedBy.split("@")[0] + ' (On Behalf - Bulk)' : requestData?.requestedBy?.split("@")[0]) : requestedBy.split("@")[0]) ?? data.user)} </span>}
                                    {(data != null && (data.requestType.toUpperCase() === UIConstants.RequestType.Remove.toUpperCase() || data.requestType.toUpperCase() === UIConstants.RequestType.BulkRemove.toUpperCase()) && data.mode?.toUpperCase() === 'ADMIN') && <span className={classNames.lblText} > Reason: {AppproverDetails?.IsDataLoaded && ("Removed by Admin - " + data.businessJustification)} </span>}
                                </div>
                            </div>
                            <div className={"ms-Grid-row"}>
                                <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>
                                    {this.RenderApprovalStatus()}
                                </div>
                                <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>
                                    {AppproverDetails?.IsDataLoaded && this.RenderGridData()}
                                    <br />
                                    {this.tenantData?.isExternalAADTenant && AppproverDetails?.IsDataLoaded && this.RenderBulkUserData()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    };

    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };

    private renderMessage = (message: string, messageType: MessageBarType) => {
        return <MessageBar key='FetchMsg' messageBarType={messageType} isMultiline={false}> <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span> </MessageBar>
    };
    private renderRequestHistory = () => {
        const { data } = this.props;
        const { AppproverDetails } = this.state;
        let {migrationData , requestData} = AppproverDetails?.Data || {};
        let ApprovalRequestDetails = requestData?.approvalRequest;

        let Histogram = [];
        if (data.requestType.toUpperCase() === 'REMOVE' || data.requestType.toUpperCase() === 'BULK REMOVE' || data.requestType.toUpperCase() === 'DEPROVISION'  || data.requestType.toUpperCase() === 'AUTO EXPIRED') {
            Histogram = [{
                key: 'Item-root-remove',
                activityDescription: [
                    <span key={'item-status-remove'}> {data.role} </span>,
                    <span key={'item-level-remove'} className={classNames.nameText}>
                        {data.requestType.toUpperCase() === 'REMOVE' || data.requestType.toUpperCase() === 'DEPROVISION' || data.requestType.toUpperCase() === 'BULK REMOVE' ? UIConstants.Labletext.Removed : UIConstants.Labletext.Expired} </span>,],
                activityIcon: <Icon iconName={'Trash'} />,
                timeStamp: new Date(data.completedDate).toLocaleString()
            }]
        }
        else {
            var pendingAlias = ''
            var approvedby = []
            var assignedTo = []
            var Histogram1 = []
            ApprovalRequestDetails.approvalHierarchy.forEach(approvalItem => {
               assignedTo[approvalItem.Level] = approvalItem.AssignedTo;
               if (ApprovalRequestDetails?.currentLevel == approvalItem.Level) {
                    if (approvalItem.ActionByAlias != null) {
                        approvedby.push(approvalItem.ActionByAlias);
                    }
                }
            });
            assignedTo = assignedTo[ApprovalRequestDetails?.currentLevel].split(";");
            var pendingAlias = '';
            assignedTo.forEach(element => {
                approvedby.forEach(item => {
                    if (element == item) {
                        delete assignedTo[assignedTo.indexOf(element)]
                    }
                });
                if (assignedTo[assignedTo.indexOf(element)] != null)
                    pendingAlias = pendingAlias + element + ";"
            });
            Histogram1 = ApprovalRequestDetails.approvalHierarchy.map(approvalItem => {
                if (approvalItem.IsApproved == null)
                    return {
                        activityDescription: [
                            <span className={classNames.actionText}>{' Pending with '} </span>,
                            <span className={classNames.actionByText}>{(this.tenantData.TenantName == UIConstants.Tenant.Mercury || this.tenantData.TenantName == UIConstants.Tenant.Alfred) ? approvalItem.AssignedTo : (pendingAlias != '' ? pendingAlias : '-')}</span>,
                            <span key={'item-levelScope-' + approvalItem.LevelCategory} className={classNames.actionText}>
                                {approvalItem.LevelCategory?.includes("Designated Approver ") ? ' for Scope : ' + approvalItem.LevelCategory.split("Designated Approver ")[1] : ""}
                                {approvalItem.LevelCategory?.includes("Cycle Owner Approver ") ? ' for Scope : ' + approvalItem.LevelCategory.split("Cycle Owner Approver ")[1] : ""}
                            </span>
                        ],
                        activityIcon: <Icon style={{ color: '#0078d4' }} iconName={'SyncStatusSolid'} />,
                        comments: [
                            <span key={'item-comments-' + approvalItem.Level}>{approvalItem.Notes !== null ? "Notes : " + approvalItem.Notes : ''}</span>
                        ],
                    };
                else {
                    return {
                        key: 'item-root-' + approvalItem.Level,
                        activityDescription: [
                            <span key={'item-status-' + approvalItem.Level} className={classNames.actionText}>
                                {approvalItem.IsApproved === null ? '' : 'Level ' + approvalItem.Level}
                                {approvalItem.IsApproved === null ? '' : approvalItem.IsApproved ? ' Approved by' : ' Rejected by'} </span>,
                            <span key={approvalItem.Level} className={classNames.actionByText}>
                                {approvalItem.IsApproved === null ? '' : approvalItem.ActionByAlias}
                            </span>,
                            <span key={'item-timestamp-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.ApprovalActionDate ?
                                ' On - ' + new Date(approvalItem.ApprovalActionDate).toLocaleString() : ''}
                            </span>,
                            <span key={'item-levelScope-' + approvalItem.LevelCategory} className={classNames.actionText}>
                                {approvalItem.LevelCategory?.includes("Designated Approver ") ? ' for Scope : ' + approvalItem.LevelCategory.split("Designated Approver ")[1] : ""}
                                {approvalItem.LevelCategory?.includes("Cycle Owner Approver ") ? ' for Scope : ' + approvalItem.LevelCategory.split("Cycle Owner Approver ")[1] : ""}
                            </span>
                        ],
                        activityIcon: <Icon style={{ color: approvalItem.IsApproved === null ? 'white' : approvalItem.IsApproved ? 'green' : 'red' }}
                            iconName={approvalItem.IsApproved === null ? '' : approvalItem.IsApproved ? 'SkypeCircleCheck' : 'SkypeCircleMinus'} />,
                        comments: [
                            <span key={'item-comments-' + approvalItem.Level}> {approvalItem.Notes !== null ? "Notes : " + approvalItem.Notes : ''}</span>,
                            <span key={'item-comments-' + approvalItem.Level}>{approvalItem.Notes !== null ? < br />:''}</span>,
                            <span key={'item-comments-' + approvalItem.Level}>{"Comments : "+ approvalItem.ApproverComments}</span>
                        ],
                    }
                }
            })
            Histogram1.reverse().forEach(element => {
                if (element != '') {
                    Histogram.push(element)
                }
            });
        }
        return Histogram;
    };

    private RenderGridData = (): JSX.Element => {
        const { data, isAdminScreen } = this.props;
        const { AppproverDetails } = this.state;

        let scopeColumn: any = [];
        let scopeListItems = [];
        let removeScopeListItems = [];
        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData , requestData } = Data || {};

        let ApprovalRequestDetails = requestData?.approvalRequest;
        let SAPRequestDetails = requestData?.sapRequest;
        let attributeItemList: JSX.Element[] = [<></>]
        let attributeItem: JSX.Element
        if (data.attributes && data.attributes.length > 0 && data.attributes[0].attributeValues && data.attributes[0].attributeValues.length > 0) {
            for (const attributeItem of data.attributes) {
                let mssalesList: any = []
                if (ApprovalRequestDetails || data.attributes.length > 0) {
                    if (attributeItem.attributeName?.includes('AccessType')) {
                        attributeItem.attributeName = 'AccessType';
                    }
                    if (attributeItem.attributeName?.includes('Area')) {
                        attributeItem.attributeName = 'Area';
                    }
                    scopeColumn = [
                        { key: 'item', name: attributeItem.attributeName, fieldName: 'item', minWidth: 50, maxWidth: 75, isResizable: false },
                    ];
                    if (this.props.requestId || (this.tenantData.TenantName == UIConstants.Tenant.Mercury && typeof this.props.requestId === 'undefined' && data.status == 'Complete')) {
                        if (data.requestType == 'Add' || data.requestType == 'Update') {
                            scopeListItems = attributeItem.attributeValues.filter(x => x.isRemove == false).map(x => { return { item: attributeItem.attributeName == "Region" ? (x.code + ' : ' + x.description) : (this.tenantData.TenantName == UIConstants.Tenant.MSSales ? (x.GroupCode != null ? x.GroupCode + ':' + x.code : x.code) : (x.code !== x.description ? x.code + ' : ' + x.description : x.code)) } })
                            removeScopeListItems = attributeItem.attributeValues.filter(x => x.isRemove == true).map(x => { return { item: attributeItem.attributeName == "Region" ? (x.code + ' : ' + x.description) : (this.tenantData.TenantName == UIConstants.Tenant.MSSales ? (x.GroupCode != null ? x.GroupCode + ':' + x.code : x.code) : (x.code !== x.description ? x.code + ' : ' + x.description : x.code)) } })
                        }
                        else {
                            scopeListItems = attributeItem.attributeValues.map(x => { return { item: attributeItem.attributeName == "Region" ? (x.code + ' : ' + x.description) : (this.tenantData.TenantName == UIConstants.Tenant.MSSales ? (x.GroupCode != null ? x.GroupCode + ':' + x.code : x.code) : (x.code !== x.description ? x.code + ' : ' + x.description : x.code)) } })
                        }
                    }
                    else if (attributeItem.attributeValues.length > 0) {
                        scopeListItems = attributeItem.attributeValues.split(',').map(x => {
                            const searchRegExp = /'/g;
                            const replaceWith = '';
                            const result = x.replace(searchRegExp, replaceWith);
                            let spliValue = result.split(':')
                            var formattedData = attributeItem.attributeName == "Region" || this.tenantData.TenantName == UIConstants.Tenant.MSSales ? result : spliValue[0] === spliValue[1] ? spliValue[0] : result;
                            return {
                                item: formattedData
                            }
                        });
                    }
                }
                else if (SAPRequestDetails) {
                    if (data.attributes[0].attributeValues && data.attributes[0].attributeValues.length > 0) {
                        scopeColumn = [
                            { key: 'item', name: data.attributes[0].attributeName, fieldName: 'item', minWidth: 200, maxWidth: 200, isResizable: false },
                            { key: 'status', name: 'Status', fieldName: 'status', minWidth: 75, maxWidth: 75, isResizable: false },
                        ];
                        scopeListItems = [];
                        for (const attributeItem of data.attributes[0].attributeValues) {
                            let status = '';
                            if (requestData.status.toUpperCase() === UIConstants.Status.InProgress.toUpperCase()) {
                                status = UIConstants.Status.InProgress;
                            } else {
                                let itemStatus = SAPRequestDetails.sapRoles.filter(y => y.name === data.role + attributeItem.code);
                                if (itemStatus && itemStatus.length > 0) {
                                    if (itemStatus[0].isApproved) {
                                        status = UIConstants.Status.Approved;
                                    } else {
                                        status = UIConstants.Status.Rejected;
                                    }
                                }
                            }
                            scopeListItems.push({
                                item: attributeItem.code + ' : ' + attributeItem.description,
                                status: status
                            });
                        }
                    }
                }
                if (this.tenantData.TenantName == UIConstants.Tenant.MSSales) {
                    scopeListItems.forEach(item => {
                        let scopes = [];
                        if (mssalesList.filter(x => x.item.includes(item.item?.split(":")[0])).length > 0) {
                            mssalesList.filter(x => x.item.includes(item.item?.split(":")[0]))[0].item += "," + item.item?.split(":")[1];
                        }
                        else {
                            scopes.push(item);
                        }
                        scopes.forEach(x => mssalesList.push(x));
                    });
                }
                else if (this.tenantData.TenantName == UIConstants.Tenant.Alfred) {
                    scopeListItems?.sort((a, b) => {
                        let codeA = parseInt(a.item.split(':')[0])
                        let codeB = parseInt(b.item.split(':')[0])
                        return codeA - codeB
                    })

                    removeScopeListItems?.sort((a, b) => {
                        let codeA = parseInt(a.item.split(':')[0])
                        let codeB = parseInt(b.item.split(':')[0])
                        return codeA - codeB
                    })
                }

                attributeItemList.push(<div>
                    {data.attributes && data.attributes.length > 0 && attributeItem.attributeValues && attributeItem.attributeValues.length > 0 && (
                        <div id={'myacces-req-scope'}>
                            <table className="companyTable">
                                <thead className="companyTable">
                                    <tr>
                                        <th> {attributeItem.attributeName} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.tenantData.TenantName == UIConstants.Tenant.Mercury && scopeListItems.length > 0 && ((isAdminScreen && data.status == "InProgress") || (!isAdminScreen)) && <th> Scope Addition: </th>}
                                    {this.tenantData.TenantName == UIConstants.Tenant.MSSales ?
                                        mssalesList.map((item, idx) => (
                                            <tr key={'tr' + idx} >
                                                <td key={'td' + idx} >
                                                    {item.item.includes(":") ?
                                                        <>{item.item.split(":")[0]}
                                                            {item.item.split(":")[1].split(",")?.map(y => <li>{y}</li>)}</>
                                                        : item.item
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        : scopeListItems.map((item, idx) => (
                                            <tr key={'tr' + idx} >
                                                <td key={'td' + idx} >{item.item}</td>
                                            </tr>
                                        ))}
                                    {removeScopeListItems.length > 0 && <div>
                                        <th> Scope Removal: </th>
                                        {removeScopeListItems.map((item, idx) => (
                                            <tr key={'tr' + idx} >
                                                <td key={'td' + idx} >{item.item}</td>
                                            </tr>
                                        ))}
                                    </div>}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>)
            }
        }
        else if (this.tenantData.TenantName == UIConstants.Tenant.MSPlancast && !(data.role.includes("Administrator") || data.role.includes("Support"))) {
            attributeItemList.push(<div id={'myacces-req-scope'}>
                <table className="companyTable">
                    <thead className="companyTable">
                        <tr>
                            <th> Area </th>
                        </tr>
                    </thead>
                    <tbody>
                        Scope values are reflected as the same as MSSales access
                    </tbody>
                </table>
            </div>);
        }
        attributeItem = <div>{attributeItemList}</div>
        return attributeItem;
    };

    private RenderBulkUserData = (): JSX.Element => {
        const { data } = this.props;
        const { AppproverDetails } = this.state;

        let scopeColumn: any = [];
        let scopeListItems = [];

        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData, requestData } = Data || {};

        let ApprovalRequestDetails = requestData?.approvalRequest;
        let userItemList: JSX.Element[] = [<></>]
        let attributeItem: JSX.Element
        if (data.properties && data.properties.bulkRequestUserDetails && data.properties.bulkRequestUserDetails.length > 0) {
            userItemList.push(<div>
                <div id={'myacces-req-userData'}>
                    <table className="companyTable" style={{ lineHeight: 1 }}>
                        <thead className="companyTable">
                            <tr key={'tr-user'}>
                                <td key={'td-user-name-key'} className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>
                                    <th> {"User UPN"} </th>
                                </td>
                                <td key={'td-role-name-key'} className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>
                                    <th> {"Role Name"} </th>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>)
            for (const user of data.properties.bulkRequestUserDetails) {
                userItemList.push(<div>
                    <div id={'myacces-req-userData'} style={{ wordBreak: 'break-word' }}>
                        <table className="companyTable" style={{ lineHeight: 1 }}>
                            <tbody>
                                <tr key={'tr-user'}>
                                    <td key={'td-user-name-key'} className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>{user.userUPN}</td>
                                    <td key={'td-role-name-key'} className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>{user.roleName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>)
            }
        }
        attributeItem = <div>{userItemList}</div>
        return attributeItem;
    }

    private RenderApprovalStatus = (): JSX.Element => {
        const { data, isAdminScreen, requestedBy } = this.props;
        const { AppproverDetails, requestedByUser } = this.state;

        let ApprovalResponse = AppproverDetails;

        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData, requestData } = Data || {};

        let ApprovalRequestDetails = requestData?.approvalRequest;
        let SAPRequestDetails = requestData?.sapRequest;
        if (AppproverDetails?.Data != null && ApprovalRequestDetails == null && data.role == UIConstants.PermissionTypes.MercuryInforma && data?.requestType != UIConstants.RequestType.Remove) {
            return (<div>{this.renderMessage('This role access was provided along with Basic/P&L roles.', MessageBarType.info)} </div>)
        }
        if (this.tenantData?.TenantName.toLowerCase() == UIConstants.Tenant.Mercury.toLowerCase() && data?.requestType != UIConstants.RequestType.Remove && requestedByUser != '' && requestedByUser != null && requestedByUser.split('@')[0] != data?.user.split('@')[0]) {
            return (<div>{this.renderMessage('Auto approved for Executive', MessageBarType.info)} </div>)
        }
        if (data.requestType.toUpperCase() === UIConstants.RequestType.Remove.toUpperCase() || data.requestType.toUpperCase() === 'BULK REMOVE' || data.requestType.toUpperCase() === 'DEPROVISION' || data.requestType.toUpperCase() === 'AUTO EXPIRED') {
            return <div>{this.renderRequestHistory().map(
                (item: { key: string | number }) => (<ActivityItem {...item} key={item.key} className={classNames.activityRoot} />)
            )}</div>
        }
        else if (data.status == "Cancelled:InvalidApprovers") {
            return <div>{this.renderMessage(UIConstants.Messages.InvalidProcessingError, MessageBarType.info)}</div>
        } 
        else if (ApprovalRequestDetails) {
            return <div>
                <Label>Approval Stage : {ApprovalRequestDetails.currentLevel} / {ApprovalRequestDetails.approvalLevels}</Label>
                <Label>Submitted On : {new Date(requestData.requestedDate).toLocaleString()}</Label>
                {this.renderRequestHistory().map(
                    (item: { key: string | number }) => (
                        <ActivityItem
                            {...item}
                            key={item.key}
                            className={classNames.activityRoot}
                        />
                    )
                )}
            </div>
        } else if (SAPRequestDetails) {
            return <div>
                <Label>System : {SAPRequestDetails.system}</Label>
                <Label>Status : {requestData.status}</Label>
                <Label>Request No : {SAPRequestDetails.sapRequestNo}</Label>
                {(SAPRequestDetails.sapRequestNo) &&
                    <div>Click <Link target="_blank" href={this.sapRequestStatusEndPoint} > here</Link> to check status in SAP</div>}
            </div>
        } else {
            if (ApprovalResponse?.IsDataLoaded) {
                if (ApprovalResponse?.IsSuccess && migrationData) {
                    return <>
                        <Label>Completed On : <span key={'item-timestamp-mig'} className={classNames.actionText}>{migrationData?.requestedDate ?
                            new Date(migrationData?.requestedDate).toLocaleString() : 'N/A'}
                        </span> </Label>

                    </>
                } 
                 else if (data.requestType && this.tenantData.TenantName == UIConstants.Tenant.Mercury && data.status.toUpperCase() == "COMPLETE") {
                    if (isAdminScreen) {
                        return <div>{this.renderMessage(UIConstants.Messages.RequestStatusNotFound, MessageBarType.error)}</div>
                    }
                    return <div>{this.renderMessage(UIConstants.Messages.ScopeRemovalRequest, MessageBarType.info)}</div>
                } else if (data.status.toLowerCase() == "cancelled:invalidapprovers") {
                    return <div>{this.renderMessage(UIConstants.Messages.InvalidProcessingError, MessageBarType.info)}</div>
                }  else {
                    return <div>{this.renderMessage(UIConstants.Messages.RequestStatusNotFound, MessageBarType.error)}</div>
                }
            } else {
                return <Spinner size={SpinnerSize.xSmall} label="loading..." ariaLive="assertive" labelPosition="bottom" />
            }
        }
    };
}
