import React from 'react';
import _ from "lodash";
import { Checkbox, ComboBox, DefaultButton, DialogType, IComboBoxOption, IComboBoxStyles, Text, IDropdownOption, ILabelStyles, IStyleSet, ITextField, Label, MessageBarType, PrimaryButton, Spinner, SpinnerSize, TextField, getId, IStyle, ILabelStyleProps, IStyleFunctionOrObject, Link } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import ITenant from '../../shared/models/Tenant.model';
import { AttributePicker } from './AttributePicker';
import { DialogModel } from '../../shared/models/Dialog.model';
import { RoleDetails, Attributes, AttributeValues, AccessRequestSubmissionModel } from '../../shared/models/UserAccess.model';
import { Role, AttributeConfig, AttributeType } from "../../shared/models/Role.model"
import { ModalDialog } from '../ModalDialog';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { EmployeeDetails } from '../../shared/models/EmployeeDetails';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { Permission, PermissionRender, ApprovedScopes, PlancastModel  } from '../../shared/models/Mercury';
import CheckboxTree from 'react-checkbox-tree';
import { MyCurrentAccessModel } from '../../shared/models/MyCurrentAccess.model';
import ConfigurationAPI from '../../shared/api/Configuration.api';


const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350, width: 350 } };
export interface IPlancastRequestState {
    selectedRole?: Role;
    selectedAttributes?: Attributes[];
    userDetails: IUser;
    editRequest: boolean;
    hideDialog: boolean;
    saveInProgress: boolean;
    showMessage: boolean;
    selectedPlatform: string;
    selectedForm: string;
    businessJustification: string;
    plancastModelValues: PlancastModel[];
    messageBarType: MessageBarType;
    message: string;
    attributes: any;
    requestType: string;
    messageType: MessageBarType;
    hideBj: boolean;
    autoHideMessage: boolean;
    requestorPrincipalId: string;
    modalDialogContent: any;
    attributeConfig: AttributeConfig[];
    setAttributeFocus: boolean;
    scompData: any;
    attributesLoaded: boolean;
    isAlternateManager: string;
    isAlternateManagerChecked: boolean;
    alternateManagerAlias: string;
    pickerCtrlKey: number;
    ManagerAlias: EmployeeDetails;
    selectedFormList : any;
    tenantData: ITenant;
    showAdditional : boolean;
    requestorPickerCtrlKey: number;
    showAlternateManager: boolean;
    myaccess: MyCurrentAccessModel;
    approvedScopes: ApprovedScopes;
    scopeValue: string;
    managerCEOMinus1or2: string;
    quizUrl: string;
}
export interface IPlancastRequestProperty {
    tenantData: ITenant;
    toggleProgressBar? : any;
    toggleMessageBar? : any;
    messageBarRef: React.RefObject<HTMLDivElement>;
}

export default class PlancastRequest extends React.Component<IPlancastRequestProperty, IPlancastRequestState> {
    private refBJ = React.createRef<ITextField>();
    private scopeRef = React.createRef<HTMLSelectElement>();
    private tenantData: ITenant = null;
    private submitClicked: boolean = false;
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }

    public state: IPlancastRequestState = {
        selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
        selectedAttributes: [],
        userDetails: null,
        editRequest: false,
        hideDialog: true,
        saveInProgress: false,
        showMessage: false,
        selectedPlatform: '',
        selectedForm: '0',
        plancastModelValues: [],
        businessJustification: '',
        messageBarType: MessageBarType.warning,
        message: '',
        attributes: null,
        requestType: 'Add',
        messageType: MessageBarType.info,
        hideBj: true,
        autoHideMessage: true,
        requestorPrincipalId: '',
        modalDialogContent: this._modalDialogContent,
        attributeConfig: [],
        setAttributeFocus: false,
        scompData: null,
        attributesLoaded: false,
        isAlternateManager: '',
        isAlternateManagerChecked: false,
        alternateManagerAlias: '',
        pickerCtrlKey: 0,
        ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' },
        tenantData: null,
        showAdditional : false,
        requestorPickerCtrlKey: 0,
        showAlternateManager: true,
        myaccess: { approvedAccess: [], pendingRequests: [] },
        approvedScopes: { scopeValues: [], hasAdditionalAccess: false },
        scopeValue: "",
        managerCEOMinus1or2: '',
        selectedFormList: [],
        quizUrl: ''
    };
    public constructor(props) {
        super(props);
    }
    /**
     * React Life cycle Events
     */
  public async componentDidMount() {
    const { tenantData } = this.props;
    const { plancastModelValues, myaccess, scopeValue, quizUrl } = this.state;
    let userAccount = msalAuth.getAccount();
    let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
    let myaccessRes = AccessAPI.getMyAccess(tenantData.TenantId);
    let plancastData = AccessAPI.GetPlancastData();
    let scopeValueRes = ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.PlanCastConfig);
    let quizUrlRes = ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.QuizValidation);
    Promise.all([plancastData, myaccessRes, scopeValueRes, quizUrlRes]).then((responses) => {
      let configValue = responses[2] ? JSON.parse(responses[2].replace(/'/g, `"`)) : null;
      this.setState({ userDetails: user, tenantData: tenantData, editRequest: false, plancastModelValues: responses[0], myaccess: responses[1].Data, scopeValue: configValue?.ScopeValue, quizUrl: responses[3] });
    });
  }

    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { saveInProgress, selectedPlatform, selectedForm, plancastModelValues, businessJustification, isAlternateManagerChecked, requestorPrincipalId,
            isAlternateManager, pickerCtrlKey, selectedFormList, showAlternateManager, ManagerAlias, managerCEOMinus1or2, selectedRole } = this.state
  
        
        return <React.Fragment>
            <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                    <div>
                        <Label required={true} styles={labelStyles}>{UIConstants.Platform}</Label>
                        <select id={'drpGrp-pg'} value={selectedPlatform}
                            title={UIConstants.Platform} required={true}
                                onChange={this.onPlatformNameChange}
                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                            <option value="0">-- Select --</option>
                            {this.getPlatforms()?.map(x => { return <option key={'drpGrp-pg' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                            </select>
                        </div>
                
                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        {this.state.selectedPlatform != '' && selectedFormList != null && selectedFormList.length>0 && < div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                        <div style={{ display: "inline-block" }}>
                        <Label required={true} styles={labelStyles}>{UIConstants.Form}</Label>
                        <select id={'drpGrp-pl'} value={selectedForm}
                            title={UIConstants.Form} required={true}
                            onChange={(event) => { this.onFormChange(event) }}
                            style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                            <option value="0">-- Select --</option>
                            {selectedFormList && selectedFormList.map((x, index) => { return <option key={'drpGrp-pl' + index + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                        </select>
                        </div>
                    {(this.state.quizUrl != null && selectedRole.id != '0' && plancastModelValues.filter(x => x.id == selectedPlatform)[0].forms.filter(x => x.formId == selectedRole.id).length > 0 && plancastModelValues.filter(x => x.id == selectedPlatform)[0].forms.filter(x => x.formId == selectedRole.id)[0].actions.filter(x => x.includes('QuizLink:')).length > 0) &&
                        <div id="quiz" style={{ display: "inline-block", verticalAlign: "top", margin: "29px 0px 0px 30px" }}>
                            <Label styles={labelStyles}>
                                Required:
                                {plancastModelValues.filter(x => x.id == selectedPlatform)[0].forms.filter(x => x.formId == selectedRole.id)[0]?.actions.filter(x => x.includes('QuizLink:')).map((quizLink, index, array) => {
                                    // Define custom quiz labels
                                    let quizLabel = 'Launch Quiz';
                                    return (
                                        <span key={index}>
                                            <Link target="_blank" href={this.state.quizUrl.replace('#quizendpoint#', quizLink.split(':')[1])}>
                                                {quizLabel}
                                            </Link>
                                            {index < array.length - 1 && ' / '}
                                        </span>
                                    );
                                })}
                            </Label>
                        </div>
                    }
                        </div>}
                    </div>
                </div>
                    <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                        <TextField
                            componentRef={this.refBJ}
                            styles={{ root: { marginTop: 10 } }} label={UIConstants.BusinessJustfication} multiline rows={3} maxLength={1000} value={businessJustification}
                            onChange={this.onJustificationChange} required
                            description={(1000 - businessJustification.length) + ' / 1000 Characters left'}
                        />
                <div className="float-right">
                    <br />
                    <div>
                        <DefaultButton onClick={this.onResetClick} disabled={saveInProgress == true} > {UIConstants.ButtonText.Reset} </DefaultButton>
                        <span>&nbsp;</span>
                        <PrimaryButton disabled={(ManagerAlias?.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() == 'CEO' && !isAlternateManagerChecked) || (saveInProgress == true)} onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                    </div>
                    <br />
                    <div>
                            <ModalDialog hidden={this.state.hideDialog}
                            onDismiss={this.closeDialog}
                            dialogContentProps={this.state.modalDialogContent}
                            modalProps={{
                                titleAriaId: this._labelId,
                                subtitleAriaId: this._subTextId,
                                isBlocking: false,
                                styles: { main: { maxWidth: 450 } },
                            }}
                        >
                        </ModalDialog>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    };


    private validateManager = () => {
        AccessAPI.ValidateUserJobTitle(this.state.ManagerAlias.upn).then(res => {
            if (res) {
                this.setState({ managerCEOMinus1or2: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
            }
            else {
                this.setState({ managerCEOMinus1or2: '' })
            }
        });
    }

    private getPlatforms = (): IDropdownOption[] => {
        const { plancastModelValues} = this.state;
        let selectedPlatformList = plancastModelValues.map(y => { return { key: y.id, text: y.platformName } });
        selectedPlatformList = _.uniqBy(selectedPlatformList, 'key');
        return selectedPlatformList.sort((a, b) => (a.text < b.text) ? -1 : 1).map(x => { return { key: x.key, text: x.text } });
    };
    
    private onPlatformNameChange = (event) => {
        const { plancastModelValues } = this.state;
        let plList: any = [];
        if (event.target.value == '0') {
            this.setState({
                selectedPlatform: event.target.value, selectedForm: '', selectedAttributes: [], selectedFormList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
            })
        }
        else {
            let selectedplatForm = plancastModelValues.filter(x => x.id == event.target.value);
            if (selectedplatForm.length == 1 && selectedplatForm[0].platformName.toLowerCase() == selectedplatForm[0].forms[0].formName.toLowerCase()) {
                plList = [];
                this.setState({
                    selectedPlatform: event.target.value, selectedForm: selectedplatForm[0].forms[0].formId, selectedAttributes: [], showAlternateManager: true, selectedFormList: plList, selectedRole: { id: selectedplatForm[0].forms[0].formId, name: selectedplatForm[0].forms[0].formName, typeId: selectedplatForm[0].id, type: selectedplatForm[0].platformName, groupId: '0', group: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: selectedplatForm[0].forms[0].approvalPolicy }
                })
            }
            else {
                plList = plancastModelValues.filter(x => x.id == event.target.value)[0]?.forms.map(y => { return { key: y.formId, text: y.formName } });
                this.setState({
                    selectedPlatform: event.target.value, selectedForm: '0', selectedAttributes: [], showAlternateManager: true, selectedFormList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
                })
            }
           
        }
    };

    private onFormChange(event) {
        const { plancastModelValues, selectedPlatform } = this.state;
        if (event.target.value != '0') {
            let selectedFormData = plancastModelValues.filter(x => x.id == selectedPlatform)[0].forms.filter(x => x.formId == event.target.value)[0];
            let selectedPlatformData = plancastModelValues.filter(x => x.id == selectedPlatform)[0];
            this.setState({
                selectedRole: { id: event.target.value, name: selectedFormData.formName, typeId: selectedPlatformData.id, type: selectedPlatformData.platformName, scopes: [], description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: selectedFormData.approvalPolicy }, selectedAttributes: [], selectedForm: selectedFormData.formId
            });
        }
        else {
            this.setState({
                selectedRole: { id: event.target.value, name: null, scopes: [], description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }, selectedAttributes: [], selectedForm: ''
            });
        }
    }
    private onJustificationChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 1000) {
            this.setState({ businessJustification: newValue || '' });
        } else {
            this.setState({ message: UIConstants.Messages.JustificationMaxLength, messageBarType: MessageBarType.warning })
        }
    };
    private setInitialState() {
        this.setState({
            selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
        })
    };
    private getRolesDetails = (): RoleDetails => {
        let { selectedRole, selectedAttributes } = this.state;
        let role: RoleDetails = {
            typeId: selectedRole.typeId,
            type: selectedRole.type,
            groupId: selectedRole.groupId,
            group: selectedRole.group,
            roleId: selectedRole.id,
            roleName: selectedRole.name,
            roleDescription: '',
            attributes: selectedAttributes,
            actions: selectedRole.permissions && selectedRole.permissions.length > 0 ? selectedRole.permissions[0].actions : [],
            approvalPolicy: selectedRole.approvalPolicy
        };
        return role;
    };
    private saveAccessRequest = (): void => {
        const { scopeValue, businessJustification, requestType, alternateManagerAlias, isAlternateManagerChecked, requestorPrincipalId, editRequest, myaccess } = this.state;
        const { toggleProgressBar, toggleMessageBar } = this.props;
        let t = this;
        let data: AccessRequestSubmissionModel = {
            tenantId: this.props.tenantData.TenantId,
            tenantName: this.props.tenantData.TenantName,
            businessJustification: businessJustification,
            scope: scopeValue,
            role: this.getRolesDetails(),
            requestType: requestType,
            requestorPrincipalId: requestorPrincipalId,
            signatureVerified: false,
            termsAndConditionsApplied: false,
            alternateMangerAlias: isAlternateManagerChecked ? alternateManagerAlias?.split('@')[0] : null,
            managerAlias: this.state.ManagerAlias?.upn?.split('@')[0],
            editRequest: editRequest
        };
        toggleProgressBar(true);
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        window.scrollTo(0, 0);
        this.props.messageBarRef.current.scrollIntoView({ behavior: 'smooth' });
        this.props.messageBarRef.current.focus();
        AccessAPI.saveUserAccessAsync(data).then(async res => {
            if (res.status && res.status === 200) {
                this.submitClicked = false;
                toggleProgressBar(false);
                t.setState({
                    saveInProgress: false,
                    messageType: MessageBarType.success,
                    message: UIConstants.Messages.SaveSuccess,
                    showMessage: false,
                    hideBj: true,
                    businessJustification: '',
                    autoHideMessage: false,
                    requestorPrincipalId: '',
                    selectedForm: '',
                    selectedPlatform: '',
                    selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' },
                    hideDialog: true,
                    attributesLoaded: true,
                    pickerCtrlKey: this.state.pickerCtrlKey + 1,
                    alternateManagerAlias: '',
                    isAlternateManagerChecked: false,
                    isAlternateManager: '',
                    editRequest: false,
                    selectedFormList: [],
                    requestorPickerCtrlKey: this.state.requestorPickerCtrlKey + 1,
                });
                this.setInitialState(); // to reset the state of request screen
                toggleMessageBar(UIConstants.Messages.SaveSuccess, MessageBarType.success);
            } else if ((res.status && res.status !== 200) || res.message) {
                t.setState({
                    messageType: MessageBarType.warning,
                    message: res.message,
                    showMessage: true,
                    saveInProgress: false,
                });
                this.submitClicked = false;
                toggleProgressBar(false);
                toggleMessageBar(res.message, MessageBarType.warning);
            }
        });
    };
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }
        this.setState({ editRequest: false, hideDialog: false, modalDialogContent: this._modalDialogContent });
    };
    private ResetUI = (): void => {
        this.setState({ 
            selectedForm: '', selectedPlatform: '', selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }, businessJustification: '', hideDialog: true, attributesLoaded: true, pickerCtrlKey: this.state.pickerCtrlKey + 1, alternateManagerAlias: '', isAlternateManagerChecked: false, isAlternateManager: '', showMessage: false, selectedFormList: [], requestorPickerCtrlKey: this.state.requestorPickerCtrlKey + 1
        });
        this.submitClicked = false;
        this.props.toggleMessageBar('', MessageBarType.warning);
    };
    private ValidateData = (): ValidationResponse => {
        const { selectedRole, businessJustification, isAlternateManagerChecked, alternateManagerAlias, selectedAttributes, selectedPlatform, selectedForm } = this.state;
        const { toggleMessageBar } = this.props;
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;
        if (selectedPlatform == '0' || selectedPlatform == null || selectedPlatform =='') {
            errorMessage = errorMessage == null ? UIConstants.Platform : `${errorMessage}, ${UIConstants.Platform}`
        }
        else if (selectedRole.id == '0' || selectedRole.id == null) {
            errorMessage = errorMessage == null ? UIConstants.Form : `${errorMessage}, ${UIConstants.Form}`
        }
        
        // Validation case : check if Business Justification is empty
        if (businessJustification.trim().length === 0) {
            errorMessage = errorMessage == null ? UIConstants.BusinessJustfication : `${errorMessage}, ${UIConstants.BusinessJustfication}`
        }
        // Validation case : check if alternate Manager is correct
        if (isAlternateManagerChecked) {
            if (alternateManagerAlias == '') {
                errorMessage = errorMessage == null ? UIConstants.AlternateManager : `${errorMessage}, ${UIConstants.AlternateManager}`
            }
        }
        if (errorMessage) {
            toggleMessageBar(` ${errorMessage} is required.`, MessageBarType.warning);
            return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
        }
        else {
            toggleMessageBar('', MessageBarType.warning);
            return response; // Validation Success , Allow user to Submit
        }
    }
    private onSubmitClick = async (): Promise<void> => {
        
        this.submitClicked = true;
        let validationResponse = this.ValidateData();
        if (validationResponse.isValid) {
            this._modalDialogContent = {
                subText: UIConstants.Messages.SaveConfirmation,
                title: UIConstants.MessageBoxTitle.SaveAccess,
                okButtonText: UIConstants.ButtonText.Submit,
                type: DialogType.normal,
                okAction: this.saveAccessRequest,
                cancelAction: this.closeDialog,
                closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
            }
            this.setState({ showMessage: false, hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent});// Show confirmation pop-up   
           
        } else {
            // Show Error message and skip submission
            this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
        }
            
    }
}
