import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { mergeStyleSets, FontSizes, Label, ProgressIndicator, MessageBarType, DefaultButton, MessageBar, IconButton, PrimaryButton, IButton, DialogType, getId, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import { ModalDialog } from '../ModalDialog';
import { DialogModel } from '../../shared/models/Dialog.model';
import AccessAPI from '../../store/AccessAPI';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { RequestDetailsResponse, UpdateApprover, MercuryConfig } from '../../shared/models/UserAccess.model';
import CheckboxTree from 'react-checkbox-tree';
import _ from 'lodash';
import { AdminViewFilter } from '../../shared/models/AdminViewFilter.model';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import "react-checkbox-tree/lib/react-checkbox-tree.css";

export interface IUpdateRequestState {
    showPanel: boolean;
    requestId: string;
    saveInProgress: boolean;
    showMessage: boolean;
    autoHideMessage: boolean;
    message: string;
    messageType: MessageBarType;
    hideDialog: boolean;
    modalDialogContent: any;
    pickerCtrlKey: number;
    RequestDetails: RequestDetailsResponse;
    subsidaryList: any,
    subsidiaryChecked: any,
    subsidiaryExpanded: any,
    businessList: any,
    businessChecked: any,
    businessExpanded: any,
    isAlternateManager: string,
    alternateManagerAlias: string,
    managerAlias: string,
    isManager: string,
    allowManagerChange: boolean,
    disableAlternateManagerChange: boolean,
    disableManagerChange: boolean,
    hasAccess: boolean,
    lastUpdatedBy: string;
    user: IUser;
    isAlreadyUpdated: boolean;
}
export interface IUpdateRequesttProperty {
    isLoadingGlobal?: boolean;
}

type UpdateRequestProps = IUpdateRequestState & IUpdateRequesttProperty &
    RouteComponentProps<{}>;

export default class UpdateRequest extends React.Component<UpdateRequestProps> {
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    public state: IUpdateRequestState = {
        showPanel: false,
        requestId: null,
        saveInProgress: false,
        showMessage: false,
        autoHideMessage: false,
        message: null,
        messageType: MessageBarType.info,
        hideDialog: true,
        modalDialogContent: this._modalDialogContent,
        pickerCtrlKey: 0,
        RequestDetails: { Data: null, IsDataLoaded: false, IsSuccess: true },
        subsidiaryChecked: [],
        subsidiaryExpanded: [],
        businessChecked: [],
        businessExpanded: [],
        subsidaryList: [],
        businessList: [],
        isAlternateManager: '',
        alternateManagerAlias: '',
        managerAlias: '',
        isManager: '',
        allowManagerChange: false,
        disableAlternateManagerChange: false,
        disableManagerChange: false,
        hasAccess: false,
        lastUpdatedBy: '',
        user: null,
        isAlreadyUpdated: false
    };
    public constructor(props) {
        super(props);

    }
    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        // let requestId = (this.props.match.params as any).requestId;

        tenantName = tenantName.toUpperCase();
        // requestId = requestId.toUpperCase();

        let requestId = new URLSearchParams(window.location.search.toLowerCase()).get("requestid");


        if (requestId) {
            requestId = requestId.toUpperCase();
            this.setState({ requestId: requestId, user:user });
            this.ensureDataFetched(requestId, user.PrincipalId);
        }

    }

    public render(): JSX.Element {
        const { showPanel, requestId, showMessage, saveInProgress, hideDialog, modalDialogContent, isAlreadyUpdated, RequestDetails, hasAccess } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = RequestDetails || {};
        return <React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>
                {(showMessage) && this.renderMessage()}
                {(saveInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            {
                (requestId) ? <div>

                    {(IsDataLoaded) ? <div>
                        {!hasAccess ? <div style={{ "position": "absolute", top: "50%", left: "50%", fontSize: 18, fontWeight: 600 }}>{'Access Forbidden'}</div> : (IsSuccess && Data) ? <div id={'div-invite-section'} style={{ padding: '1%', paddingTop: '2%' }} >
                            <div key="grid-invite" className={"ms-Grid"} dir="ltr">
                                <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                                    <div className={"ms-Grid-col ms-sm8 ms-md8 ms-lg10"}>
                                        <Label style={{ paddingLeft: 5, fontSize: 20 }} > <h1 role="heading" aria-label={UIConstants.PageTitle.UpdateRequest} aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}> {UIConstants.PageTitle.UpdateRequest}</h1></Label>
                                    </div>
                                </div>
                                <div key='grid-row-invite' className={"ms-Grid-row"} >
                                    {this.renderData()}

                                    <div className={"ms-Grid-row"}>
                                        <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>

                                        </div>
                                        <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg6"}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div key='grid-row-action' className={"ms-Grid-row"}>
                                <br aria-hidden={true} />
                                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg7"} >
                                    <div className="float-right">
                                        <DefaultButton disabled={isAlreadyUpdated} role="button" tabIndex={0} onClick={this.onCancelClick} > {UIConstants.ButtonText.Cancel} </DefaultButton>
                                        <span>&nbsp;</span>
                                        <PrimaryButton disabled={isAlreadyUpdated} role="button" tabIndex={0} onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                                    </div>
                                </div>
                                <div>
                                    <ModalDialog hidden={hideDialog}
                                        onDismiss={this.closeDialog}
                                        dialogContentProps={modalDialogContent}
                                        modalProps={{
                                            titleAriaId: this._labelId,
                                            subtitleAriaId: this._subTextId,
                                            isBlocking: false,
                                            styles: { main: { maxWidth: 450 } },
                                        }}
                                    >
                                    </ModalDialog>
                                </div>
                            </div>
                        </div> : <div style={{ "position": "absolute", top: "50%", left: "50%", fontSize: 18, fontWeight: 600 }}>{requestId ? 'Invalid RequestId' : 'RequestId Not Found'}</div>}
                    </div> : <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />}
                </div> : <div style={{ "position": "absolute", top: "50%", left: "50%", fontSize: 18, fontWeight: 600 }}>RequestId Not Found</div>
            }
        </React.Fragment>
    }
    private renderData(): JSX.Element {
        const { showPanel, requestId, pickerCtrlKey, RequestDetails, isAlternateManager, isManager, allowManagerChange, disableAlternateManagerChange, disableManagerChange, isAlreadyUpdated } = this.state;

        const { IsDataLoaded, IsSuccess, Data } = RequestDetails || {};

        var defSub = Data.role.attributes.length>0? Data.role.attributes?.filter(x => x.attributeName == 'Default Reporting Subsidiary')[0]?.attributeValues:[];
        let defSubText = Data.role.attributes.length > 0 ? defSub?.map(x => { return x.description }).join(";") : "";
        return <div className={"ms-Grid"} dir="ltr">
            <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg7"}>
                <div id={'dv-asset-details'}>
                    <table className="tb-reg-req-detail">
                        <tbody>
                            <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label> Request Id </Label></span></td>
                                <td key={'td-ast-id-val'} ><span>{Data.id}</span></td>
                            </tr>
                            <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Requested Date </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{new Date(Data.requestedDate).toLocaleString()}</span></td>
                            </tr>
                            <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Requestor </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{Data.requestorAlias}</span></td>
                            </tr>
                            <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Role </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{Data.role?.roleName}</span></td>
                            </tr>
                            {Data.tenantName == UIConstants.Tenant.MSSales && Data.role?.attributes.length>0 && <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Default Reporting Subsidiary </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{defSubText}</span></td>
                            </tr>}
                            <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Business Justification </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{Data.businessJustification}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            {Data.tenantName == UIConstants.Tenant.MSSales && Data.role?.attributes.length > 0 && 
            <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg7"}>
                <div id={'dv-asset-details'}>
                    <table className="tb-reg-req-detail">
                        <tbody>
                            <tr key={'tr-ast-id'} ><td>{this.renderTreeView()}</td></tr>
                        </tbody>
                    </table>
                </div>
                </div>}

            {Data.tenantName == UIConstants.Tenant.Mercury && Data.role?.attributes.length > 0 && <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg7"}>
                <div id={'dv-asset-details'}>
                    <table className="tb-reg-req-detail">
                        <tbody>
                            <tr key={'tr-ast-id'}>{this.renderAttributes()}</tr>
                        </tbody>
                    </table>
                </div>
            </div>}
            <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg7"}>
                <div id={'dv-asset-details'}>
                    <table className="tb-reg-req-detail">
                        <tbody>
                            {!disableManagerChange && <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Approver (Manager) </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span>{!allowManagerChange ? (Data.properties.managerAlias) : (<div style={{ width: 275 }}><PeoplePicker
                                    key={pickerCtrlKey}
                                    type={PeoplePickerType.Normal}
                                    contentType={PeoplePickerContentType.FTEUser}
                                    selectionMode={PeoplePickerSelectionMode.Single}
                                    onchange={(items) => { this.onPeoplePickerManagerChange(items) }}
                                    defaultSelectedItems={[]}
                                    {...this.props}
                                    setFocusOnPicker={false}
                                    defaultUserAlias={Data.properties.managerAlias}
                                    isPickerDisabled={isAlreadyUpdated}
                                    hideDirectFTE={false}
                                ></PeoplePicker></div>)}
                                    {isManager.includes("Validating Data") ? <><br /><Spinner size={SpinnerSize.small} label="Validating..." ariaLive="assertive" labelPosition="right" /></> : <div style={{ color: 'red', fontSize: 13, width: '100%' }}>{!isAlreadyUpdated && isManager}</div>}</span></td>
                            </tr>
                            }
                            {!disableAlternateManagerChange && <tr key={'tr-ast-id'} >
                                <td key={'td-ast-id-key'} ><span><Label > Alternate FTE approver </Label> </span></td>
                                <td key={'td-ast-id-val'} ><span><div style={{ width: 275 }}><PeoplePicker
                                    key={pickerCtrlKey}
                                    type={PeoplePickerType.Normal}
                                    contentType={PeoplePickerContentType.FTEUser}
                                    selectionMode={PeoplePickerSelectionMode.Single}
                                    onchange={(items) => { this.onPeoplePickerChange(items) }}
                                    defaultSelectedItems={[]}
                                    {...this.props}
                                    setFocusOnPicker={false}
                                    defaultUserAlias={Data.properties.alternateManagerAlias}
                                    isPickerDisabled={isAlreadyUpdated}
                                    hideDirectFTE={true}
                                ></PeoplePicker></div>
                                    {isAlternateManager.includes("Validating Data") ? <><br /><Spinner size={SpinnerSize.small} label="Validating..." ariaLive="assertive" labelPosition="right" /></> : <div style={{ color: 'red', fontSize: 13, width: '100%' }}>{!isAlreadyUpdated && (isAlternateManager ?? '')}</div>}
                                </span></td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    private renderMessage = () => {
        let { message, messageType, autoHideMessage, isAlreadyUpdated } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds
        if ((this.props.match.params as any).tenantName != UIConstants.Tenant.MSSales) {
            setTimeout(() => {
                this.alertMsgCloseBtnRef.current.focus();
            }, 100) // set the focus to close button
        }
        return <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
            actions={
                <div role="alert" aria-label={message} hidden={isAlreadyUpdated}>
                    <IconButton componentRef={this.alertMsgCloseBtnRef} iconProps={{ iconName: 'Clear' }}
                        title="Close" ariaLabel="close" onClick={() => { this.setState({ showMessage: false, setFocusOnPicker: true }) }} />
                </div>
            }
            messageBarType={messageType}
            isMultiline={false}
        >
            {message}
        </MessageBar>
    };
    private renderTreeView = () => {
        let { subsidaryList, subsidiaryExpanded, businessList, businessExpanded } = this.state;

        return <>
            <div key='grid-row-filter-lbl-tit' className={"ms-Grid-row"} style={{ paddingBottom: 5 }}>
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                    <Label styles={{ root: { marginTop: 7, fontSize: 17 } }}>Subsidiary Selection</Label>
                    <Text style={{ fontStyle: 'italic' }}>(Area / Subsidiary)</Text></div>
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0 }}>
                    <Label styles={{ root: { marginTop: 7, fontSize: 17 } }}>Business Selection</Label>
                    <Text style={{ fontStyle: 'italic' }}>(Business Group / Business)</Text></div>
            </div>
            <div key='grid-row-filter-tree' className={"ms-Grid-row"} style={{ paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible', lineHeight:1.5 }}>
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                    <CheckboxTree
                        nodes={subsidaryList}
                        expanded={subsidiaryExpanded}
                        onExpand={
                            (expanded, node) => {
                                if (node.expanded) {
                                    subsidiaryExpanded.push(node.value);
                                } else {
                                    const index = subsidiaryExpanded.indexOf(node.value);
                                    if (index > -1) {
                                        subsidiaryExpanded.splice(index, 1);
                                    }
                                }
                                this.setState({ subsidiaryExpanded: subsidiaryExpanded })
                            }
                        }
                        nativeCheckboxes={true}
                        icons={{
                            expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                            expandOpen: <i className="fa fa-minus-square" aria-hidden="true"></i>,
                            parentClose: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>,
                            parentOpen: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>,
                            leaf: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>
                        }}
                    />
                </div>
                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                    <CheckboxTree
                        nodes={businessList}
                        disabled={true}
                        expanded={businessExpanded}
                        onExpand={
                            (expanded, node) => {
                                if (node.expanded) {
                                    businessExpanded.push(node.value);
                                } else {
                                    const index = businessExpanded.indexOf(node.value);
                                    if (index > -1) {
                                        businessExpanded.splice(index, 1);
                                    }
                                }
                                this.setState({ businessExpanded: businessExpanded })
                            }
                        }
                        nativeCheckboxes={true}
                        icons={{
                            expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                            expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                            parentClose: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>,
                            parentOpen: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>,
                            leaf: <i className="fa fa-check-square" aria-hidden="true" style={{ color: '#6e6e6e' }}></i>
                        }}
                    />
                </div>
            </div></>
    }
    private renderAttributes = () => {
        const { RequestDetails } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = RequestDetails || {};
        let multiAttributes = Data.role.attributes.map(x => {
            var isStatutory = (Data.role.roleName == 'All companies' || (Data.role.roleName == 'Specific Companies' && x.attributeName != 'Specific Companies'));
            if (Data.tenantName == UIConstants.Tenant.Mercury && x.attributeName == 'External Reporting') {
                return {
                    attributeName: x.attributeName,
                    attributeValueText: "True"
                }
            }
            else if (Data.tenantName == UIConstants.Tenant.Mercury && x.attributeName != 'Specific Companies') {
                return {
                    attributeName: isStatutory ? "Statutory" : x.attributeName,
                    attributeValueText: x.attributeValues.map(y => { return isStatutory ? "True" : y.description }).join('; ')
                }
            }
            else {
                return {
                    attributeName: isStatutory ? "Statutory" : x.attributeName,
                    attributeValueText: x.attributeValues.map(y => { return (isStatutory ? "True" : (y.code !== y.description ? y.code + ':' + y.description : y.code)) }).join('; ')
                }
            }
        })
        let attributes = []
        multiAttributes.forEach(x => {
            attributes.push(
                <tr key={'tr-ast-id'} >
                    <td key={'td-ast-id-key'} ><span><Label styles={{ root: { fontSize: 16 } }}>{x.attributeName}</Label></span></td>
                    <td key={'td-ast-id-val'} ><span>{x.attributeValueText}</span></td>
                </tr>
            );
        })
        return <>{attributes}</>;
    }
    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private onCancelClick = (): void => {

    };
    private onSubmitClick = async (): Promise<void> => {
        const { requestId, alternateManagerAlias, lastUpdatedBy, user, RequestDetails, managerAlias } = this.state;
        this.setState({ saveInProgress: true, showMessage: false });
        if ((RequestDetails.Data.properties.alternateManagerUpdatedBy != null && RequestDetails.Data.properties.alternateManagerUpdatedBy != '') || (lastUpdatedBy != '' && lastUpdatedBy != null)) {
            this.setState({ showMessage: true, saveInProgress: false, message: "The approver information has already been reviewed and submitted", messageType: MessageBarType.warning, isAlreadyUpdated: true });
        }
        else {
            let data: UpdateApprover = {
                id: requestId,
                Approver: alternateManagerAlias.split('@')[0],
                Manager: managerAlias.split('@')[0]
            };
            if (alternateManagerAlias == '' || managerAlias == '') {
                this.setState({ showMessage: true, saveInProgress: false, message: "Approver information is required", messageType: MessageBarType.warning });
            }
            else {
                AccessAPI.updateMSSalesApprover(data).then(async res => {
                    if (res.status && res.status === 200) {
                        this.setState({ showMessage: true, saveInProgress: false, message: "Approver information updated successful", messageType: MessageBarType.success, lastUpdatedBy: user.Alias.split("@")[0], isAlreadyUpdated: true });
                    } else if ((res.status && res.status !== 200) || res.message) {
                        this.setState({ showMessage: true, saveInProgress: false, message: "Failed to update the approver information", messageType: MessageBarType.warning });
                    }
                });
            }
        }
    };
    private onPeoplePickerChange(items: any) {
        this.setState({ isAlternateManager: '', alternateManagerAlias: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ alternateManagerAlias: parsedData.upn, isAlternateManager: 'Validating Data' });
            if (parsedData.upn != ''  &&  parsedData.upn != null) {
                AccessAPI.ValidateUserJobTitle(parsedData.upn).then(res => {
                    if (res) {
                        this.setState({ isAlternateManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue ?" });
                    }
                    else {
                        this.setState({ isAlternateManager: '' });
                    }
                });
            }
        }
    }
    private onPeoplePickerManagerChange(items: any) {
        const { managerAlias, isManager } = this.state;
        this.setState({ isManager: '', managerAlias: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ managerAlias: parsedData.upn, isManager: 'Validating Data' });
            if (parsedData.upn != '' && parsedData.upn != null) {
                AccessAPI.ValidateUserJobTitle(parsedData.upn).then(res => {
                    if (res) {
                        this.setState({ isManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue ?" });
                    }
                    else {
                        this.setState({ isManager: '' });
                    }
                });
            }
        }
    }
    private ensureDataFetched(requestId, principalId) {
        let filter: AdminViewFilter = { principalId: null, roleId: null };
        AccessAPI.GetRequest(requestId).then(res => {
            const { IsDataLoaded, IsSuccess, Data } = res || {};

            var sub = Data.role.attributes.filter(x => x.attributeName == "Subsidiary")[0]?.attributeValues;
            var bus = Data.role.attributes.filter(x => x.attributeName == "Business")[0]?.attributeValues;
            let subGroup = _.uniqBy(sub, "GroupCode");
            let busGroup = _.uniqBy(bus, "GroupCode");
            subGroup.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1)
            busGroup.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1)
            let subTree = [];
            let subsidiaryExpanded = [];

            subGroup.forEach(x => {
                let subItems = sub.filter(y => y.GroupCode == x.GroupCode);
                subItems.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
                subTree.push({
                    value: x.GroupCode.toLowerCase(),
                    label: x.GroupDescription,
                    showCheckbox: false,
                    children: subItems.map(y => { return { value: y.code.toUpperCase(), label: y.description, showCheckbox: false } })
                })
                subsidiaryExpanded.push(x.GroupCode.toLowerCase());

            })
            let busTree = [];
            let businessExpanded = [];
            busGroup.forEach(x => {
                let subItems = bus.filter(y => y.GroupCode == x.GroupCode);
                subItems.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
                busTree.push({
                    value: x.GroupCode.toLowerCase(), label: x.GroupDescription, showCheckbox: false, children: subItems.map(y => { return { value: y.code.toUpperCase(), label: y.description, showCheckbox: false } })
                })
                businessExpanded.push(x.GroupCode.toLowerCase());

            })
            if (Data.properties.alternateManagerAlias != '' && Data.properties.alternateManagerAlias != null) {
                AccessAPI.ValidateUserJobTitle(Data.properties.alternateManagerAlias + "@microsoft.com").then(res => {
                    if (res) {
                        this.setState({ isAlternateManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue ?", alternateManagerAlias: Data.properties.alternateManagerAlias + "@microsoft.com" });
                    }
                    else {
                        this.setState({ isAlternateManager: '' });
                    }
                });
            } else { this.setState({ disableAlternateManagerChange: true }) }
            if (Data.properties.managerAlias != '' && Data.properties.managerAlias != null) {
                AccessAPI.ValidateUserJobTitle(Data.properties.managerAlias + "@microsoft.com").then(res => {
                    if (res) {
                        this.setState({ allowManagerChange: true, isManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue ?", managerAlias: Data.properties.managerAlias + "@microsoft.com" });
                    }
                    else {
                        this.setState({ allowManagerChange: false, isManager: '', managerAlias: Data.properties.managerAlias });
                    }
                });
            }
            else {
                this.setState({ disableManagerChange: true })
            }
            let approvers = '';
            let hasAccess = false;
            if ((this.props.match.params as any).tenantName == "MSSales") {
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MSSalesConfig).then(configResponse => {
                    let config = configResponse ? JSON.parse(configResponse.replace(/'/g, `"`)) : null;
                    if (config != null) filter.roleId = config.AdminRoleId;
                    AccessAPI.getProvisionedRequestData(res.Data.tenantId, filter).then(response => {
                        response?.forEach(item => {
                            if (item.roleName == "Administrator") {
                                approvers = approvers + item.principalId + ";"
                            }
                        });
                        if (approvers.includes(principalId)) {
                            hasAccess = true;
                        }
                        this.setState({ RequestDetails: res, hasAccess: hasAccess, subsidaryList: subTree, businessList: busTree, subsidiaryExpanded: subsidiaryExpanded, businessExpanded: businessExpanded, alternateManagerAlias: Data.properties.alternateManagerAlias + "@microsoft.com" });
                    });
                });
            }
            else if ((this.props.match.params as any).tenantName == "Mercury") {
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MercuryConfig).then(response => {
                    let approvers = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
                    if (approvers?.MercuryFBITeam.includes(this.state.user.Alias)) {
                        hasAccess = true;
                    }
                    this.setState({ RequestDetails: res, lastUpdatedBy: res.Data.properties.alternateManagerUpdatedBy, hasAccess: hasAccess, alternateManagerAlias: Data.properties.alternateManagerAlias + "@microsoft.com", isAlternateManager: '' });
                });
            }
            if ((Data?.properties?.alternateManagerUpdatedBy != null && Data?.properties?.alternateManagerUpdatedBy != '')) {
                this.setState({ showMessage: true, saveInProgress: false, message: "The approver information has already been reviewed and submitted", messageType: MessageBarType.warning, isAlreadyUpdated: true });
            }
        });

    }
}