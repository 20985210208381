import React from 'react';
import {  IStyle, mergeStyleSets, Link, FontWeights, FontSizes, ThemeProvider } from '@fluentui/react';
import { CoherenceHeader, initializeCentro } from '@coherence-design-system/controls';
import { CoherenceCustomizations } from '@coherence-design-system/styles';
import { Utility } from '../shared/models/Helper';
import { UIConstants } from '../shared/models/Constants';
import { SearchService } from './SearchService';
import { InitialFeedbackData } from './InitParameters';
import _ from "lodash";

// #region   Help Panel 
const HelpLinkStyles = mergeStyleSets(
    {
        helpLink: {
            margin: '8px 0',
            display: 'block',
            fontSize: FontSizes.size14,
            fontWeight: FontWeights.semibold
        } as IStyle
    }
);

const HelpSettings: JSX.Element = (
    <div>
        <Link target="_blank" href="http://go.microsoft.com/fwlink/?LinkId=518021" className={HelpLinkStyles.helpLink}>
            Data Privacy Notice
        </Link>
        <Link target="_blank" href="https://microsoft.sharepoint.com/sites/EUA" className={HelpLinkStyles.helpLink}>
            Help & Support
        </Link>
    </div>
);
// #endregion 

export class HeaderBar extends React.Component<
    {
        currentUser: any;
        logoutHandler: any;
        isAdmin: boolean;
        isExternalUser: boolean;
    },
    {
        showSuggestions: boolean,
        searchText: string,
        ocvButtonIsFocused: boolean
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            showSuggestions: false,
            searchText: '',
            ocvButtonIsFocused: false,
        };
    }
    componentDidMount(): void {
        // OCV/Centro must be initialized after the DOM is rendered.
        initializeCentro(InitialFeedbackData.clientName!, 'en', InitialFeedbackData.appId);
    }

    private _handleOcvButtonFocusState = () => {
        const overflowButton = document.getElementById('coher-header-overflow-button-id');
        if (overflowButton) {
            // set focus back to overflow button
            overflowButton.focus();
        }

        this.setState({
            ocvButtonIsFocused: false
        });
    };
    public render(): JSX.Element {
        return this.renderCoheranceHeader();
    }

    protected _renderSchemedComponents(): JSX.Element {
        return this.renderCoheranceHeader();
    }

    public renderCoheranceHeader(): JSX.Element {
        // Update the original dismiss and error functions to include the logic needed to reset focus on the feedback header button
        const ocvInitialData = { ...InitialFeedbackData };
        ocvInitialData.callbackFunctions!.onDismiss = (_isFeedbackSent?: boolean) => {
            console.log('Dismissing OCV panel');
            this._handleOcvButtonFocusState();
        };

        ocvInitialData.callbackFunctions!.onError = (_isFeedbackSent?: string | undefined) => {
            console.log('OCV panel has an error');
            this._handleOcvButtonFocusState();
        };
        return (
            <div id={'Header'}>
            <CoherenceHeader
                headerLabel='Header'
                styles={{
                    subComponentStyles: {
                        appNameLink: {
                            root: { color: 'white', boxShadow: '#FFFFFF 0px 0px 0px 0px inset !important' }
                        }
                    }
                }}
                appNameSettings={{
                    label: ( window.innerWidth > 1400 ? UIConstants.PageTitle.AppTitle : "EUA") + this.getEnvironment() + this.getServiceName(),
                    linkUrl: '/'
                }}
                searchSettings={{
                    onChange: () => undefined,
                    onSearch: (_: string) => undefined,
                    onClear: () => undefined,
                    onRender: this.onSearcRender,
                    disabled: false,
                    placeholder: UIConstants.PlaceHolderText.SearchService,
                }}
                farRightSettings={{
                    profileSettings: {
                        buttonSettings: {
                            title: 'Profile',
                            ariaLabel: 'Profile'
                        },
                        panelSettings: {
                            id:'profile-panel',
                            titleText: 'Profile',
                            fullName: this.props.currentUser.name,
                            emailAddress: this.props.currentUser.userName,
                            imageUrl: '',
                            onViewAccount: () => window.open("https://myaccount.microsoft.com/?ref=MeControl", "_blank"),
                            onLogOut: this.props.logoutHandler,
                            hasCloseButton: true,
                            closeButtonAriaLabel: "Close",
                            "aria-label": "View My Account",
                            logOutLink: "Sign",
                            styles: {
                                body: { overflowX: "hidden" }
                            }
                        }
                    },
                    helpSettings: {
                        panelSettings: { body: HelpSettings },
                    },
                    feedbackSettings: {
                        buttonSettings: {
                            title: 'Feedback',
                            ariaLabel: 'Feedback'
                        },
                        panelSettings: {
                            ocvButtonIsFocused: this.state.ocvButtonIsFocused,
                            ocvInitOptions: ocvInitialData,
                            onClick: () => {
                                this.setState({
                                    ocvButtonIsFocused: true
                                });
                                return true;
                            }
                        }
                    }
                }}
                />
            </div>
        );
    }

    private onSearcRender = () => {
        const { isExternalUser } = this.props
        return <div>
            {isExternalUser
                ?<></>
                :
                <SearchService></SearchService>}
        </div>;
    }

    private getServiceName = () => {
        let serviceName;
        let urlPathName = window.location.pathname
        if (urlPathName === '/') {
            serviceName = ''; // Remove service name from app title in the Landing pages 
        } else {
            let urlValues = urlPathName.split('/');
            let tenantName = _.last(urlValues).toUpperCase();
            let TenantData = Utility.GetTenantData();
            let tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            if (window.innerWidth >= 1750) {
                serviceName = tenantData && tenantData.TenantDisplayName ? ` | ${tenantData.TenantDisplayName} ` : '';
            }
            else if ((window.innerWidth <= 300)) {
                serviceName = tenantData && tenantData.TenantName ? `|${tenantData.TenantName} ` : '';
            }
            else {
                serviceName = tenantData && tenantData.TenantName ? ` | ${tenantData.TenantName} ` : '';
            }
        }
        return serviceName;
    }
    private getEnvironment = () => {
        let environmentConfigValue = process.env.REACT_APP_ENVIRONMENT;
        let env = '';
        if (window.innerWidth >= 350) {
            if (environmentConfigValue.toLowerCase() != 'prod') {
                let environment = environmentConfigValue.toUpperCase();
                env = environment ? `-${environment}` : '';
            }
        }
        return env;
    }
}

export class Header extends HeaderBar {
    public render(): JSX.Element {
        return <ThemeProvider {...CoherenceCustomizations}>{this._renderSchemedComponents()}</ThemeProvider>
    }
}