import React from 'react';
import { Modal, IconButton, mergeStyleSets, getTheme, getId, FontSizes, FontWeights, Link } from '@fluentui/react';
import { UIConstants } from '../../shared/models/Constants';
import _ from 'lodash';
import { Role } from '../../shared/models/Role.model';

const theme = getTheme();
const iconButtonStyles = mergeStyleSets({
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
});
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 8px 24px'
        }
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            }
        }
    }
});

const classNames = mergeStyleSets({
    activityRoot: {
        marginTop: "20px"
    },
    nameText: {
        fontSize: 14,
        fontWeight: 600,

    }, actionText: {
        fontSize: 14,
    }, actionByText: {
        fontWeight: "bold",
        fontSize: FontSizes.medium,
        color: '#0064BF'
    },
    lblText: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        // -webkit-font-smoothing: antialiased;
        fontSize: 14,
        fontWeight: 600,
        color: 'rgb(23, 23, 23)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: 0,
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word',
    }
});

interface IRequestDetailsProps {
    isAdminScreen: boolean;
    selectedRole: Role;
    approvers: any;
    onDismiss: Function;
    dataContractLinks: any;
}
interface IAccessRequestDetailState {
    showModal: boolean,
}
export default class AssetViewDetails extends React.Component<IRequestDetailsProps, IAccessRequestDetailState> {
    private _titleId: string = getId('title');
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: true,
        };

    }
    public render() {
        const { selectedRole, approvers } = this.props;
        return <React.Fragment>
            <Modal titleAriaId={this._titleId}
                isOpen={this.state.showModal} onDismiss={this._closeModal}
                isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ position: 'absolute', right: 10, paddingTop: 20, paddingBottom: 12, marginTop: 20 }} > <IconButton tabIndex={0} styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close Access Details dialog" onClick={this._closeModal as any} /> </span>
                    <span style={{ position: 'absolute', left: 25, paddingTop: 20, paddingBottom: 12, marginTop: 20 }}   ><h2 role='heading' className={"h2"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.MessageBoxTitle.AssetDetails}</h2>
                    </span>
                </div>
                <div role='presentation' className={contentStyles.body}>
                    <div style={{
                        height: '60vh',
                        width: '70vw',
                        overflow: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: 30
                    }}
                    >
                        <div id={'dv-asset-details'}>
                            <table className="tb-asset-detail">
                                <tbody>
                                    <tr key={'tr-ast-id'} >
                                        <td key={'td-ast-id-key'} ><span className={classNames.nameText}>{' Asset Id '} </span></td>
                                        <td key={'td-ast-id-val'} ><span className={classNames.actionText}>{selectedRole[0]['id']}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Asset name '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['name']}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-desc'} >
                                        <td key={'td-ast-desc-key'} > <span className={classNames.nameText}>{' Description '} </span></td>
                                        <td key={'td-ast-desc-val'} > <span className={classNames.actionText}>{selectedRole[0]['description']}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Entity name '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('entityname') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Data Classification Level '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('dataclassificationlevel') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Schema Name '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('schemaname') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Data Source Name '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('datasourcename') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Personal Data Field '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('personaldatafield') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Is DataOwner Approval Required '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('isdataownerapprovalrequired') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>

                                    <tr key={'tr-ast-pubname'} >
                                        <td key={'td-ast-pubname-key'} > <span className={classNames.nameText}>{' Publisher name '} </span></td>
                                        <td key={'td-ast-pubname-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][1].slice(16)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-do'} >
                                        <td key={'td-ast-do-key'} > <span className={classNames.nameText}>{' DataOwners '} </span></td>
                                        <td key={'td-ast-do-val'} > <span className={classNames.actionText}>{approvers[0].slice(21)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-lead'} >
                                        <td key={'td-ast-lead-key'} > <span className={classNames.nameText}>{' Publisher Privacy Leads '} </span></td>
                                        <td key={'td-ast-lead-val'} > <span className={classNames.actionText}>{approvers[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-sid'} >
                                        <td key={'td-ast-sid-key'} > <span className={classNames.nameText}>{' Publisher Request Id '} </span></td>
                                        <td key={'td-ast-sid-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][2].slice(21)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-sid'} >
                                        <td key={'td-ast-sid-key'} > <span className={classNames.nameText}>{' Service Tree Id '} </span></td>
                                        <td key={'td-ast-sid-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][3].slice(16)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-sn'} >
                                        <td key={'td-ast-sn-key'} > <span className={classNames.nameText}>{' Service Tree Name '} </span></td>
                                        <td key={'td-ast-sn-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][4].slice(18)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-ops'} >
                                        <td key={'td-ast-ops-key'} > <span className={classNames.nameText}>{' OpsEngAlias '} </span></td>
                                        <td key={'td-ast-ops-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][5].slice(14)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-dev'} >
                                        <td key={'td-ast-dev-key'} > <span className={classNames.nameText}>{' DevEngAlias '} </span></td>
                                        <td key={'td-ast-dev-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][6].slice(14)}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-sox'} >
                                        <td key={'td-ast-sox-key'} > <span className={classNames.nameText}>{' IsSOX '} </span></td>
                                        <td key={'td-ast-sox-val'} >
                                            <span className={classNames.actionText}>
                                                {selectedRole[0]['permissions'][0]['actions'][7].slice(8) == 'True' ? 'Yes' : 'No'}
                                            </span></td>
                                    </tr>
                                    <tr key={'tr-ast-cpl'} >
                                        <td key={'td-ast-cpl-key'} > <span className={classNames.nameText}>{' IsCplusAI '} </span></td>
                                        <td key={'td-ast-cpl-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'][8].slice(12) == 'True' ? 'Yes' : 'No'}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Account Id '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('accountid') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-name'} >
                                        <td key={'td-ast-name-key'} > <span className={classNames.nameText}>{' Account Id Type '} </span></td>
                                        <td key={'td-ast-name-val'} ><span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('accountidtype') !== -1)[0]?.split(":")[1]}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-link'} >
                                        <td key={'td-ast-link-key'} > <span className={classNames.nameText}>{' Data Contract Links '} </span></td>
                                        <td key={'td-ast-link-val'} > <span className={classNames.actionText}>
                                            {
                                                (() => {
                                                    if (this.props.dataContractLinks[selectedRole[0]['permissions'][0]['actions'][2].slice(21)]) {
                                                        let links = this.props.dataContractLinks[selectedRole[0]['permissions'][0]['actions'][2].slice(21)]?.split(';');
                                                        if (links.length > 1) {
                                                            return links.map((item, idx) => (
                                                                <React.Fragment key='multi-link'>
                                                                    <Link key={'link-' + idx} target="_blank" href={item} >View Data Contract {idx + 1}</Link>
                                                                    {idx != links.length - 1 ? ', ' : ''}
                                                                </React.Fragment>))
                                                        } else {
                                                            return <React.Fragment key='single-link'>
                                                                <Link key={'link'} target="_blank" href={links[0]} >View Data Contract </Link>
                                                            </React.Fragment>
                                                        }
                                                    }
                                                })()
                                            }
                                        </span></td>
                                    </tr>
                                    <tr key={'tr-ast-data'} >
                                        <td key={'td-ast-data-key'} > <span className={classNames.nameText}>{' HasClassifiedData '} </span></td>
                                        <td key={'td-ast-data-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('hasclassifieddata') !== -1)[0]?.split(" : ")[1] == 'True' || selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('hasclassifieddata') !== -1)[0]?.split(" : ")[1] == 'Yes' ? 'Yes' : 'No'}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-pii'} >
                                        <td key={'td-ast-pii-key'} > <span className={classNames.nameText}>{' HasPersonalData '} </span></td>
                                        <td key={'td-ast-pii-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('haspersonaldata') !== -1)[0]?.split(" : ")[1] == 'True' || selectedRole[0]['permissions'][0]['actions'].filter(x => x.toLowerCase().indexOf('haspersonaldata') !== -1)[0]?.split(" : ")[1] == 'Yes' ? 'Yes' : 'No'}</span></td>
                                    </tr>
                                    <tr key={'tr-ast-pii'} >
                                        <td key={'td-ast-pii-key'} > <span className={classNames.nameText}>{' EntityGroup '} </span></td>
                                        <td key={'td-ast-pii-val'} > <span className={classNames.actionText}>{selectedRole[0]['permissions'][0].actions.filter(x => x.indexOf('EntityGroup') !== -1)[0]?.split(":")[1] ?? ''}</span></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    };

    private _closeModal = (): void => {
        this.setState({ showModal: false });
        this.props.onDismiss();
    };
}

