import React from 'react';
import {
    Label, Text,
    ProgressIndicator,
    DialogType, getId, MessageBar, MessageBarType, TextField, ITextField,
} from '@fluentui/react';
import { DefaultButton, IButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import { DialogModel } from '../../shared/models/Dialog.model';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import { ModalDialog } from '../ModalDialog';
import { BulkRemoval } from '../../shared/models/UserAccess.model';

export interface AdminBulkRemovalState {
    userDetails: IUser;
    showMessage: boolean;
    removeInProgress: boolean;
    message: string;
    messageType: MessageBarType;
    removeUsersData: string;
    modalDialogContent: DialogModel;
    hideDialog: boolean;
    displayReport: boolean;
    successUsers: string;
    failedUsers: string;
    submittedUsers: string;
    invalidAADUsers: any;
    invalidEUAUsers: any;
    drsUsers: any;
    otherFailedUsers: any;
    justification: string;
    showDialogMessage: boolean;
}

type AdminBulkRemovalProps = RouteComponentProps<{}>;

export class AdminBulkRemoval extends React.Component<AdminBulkRemovalProps>  {
  private tenantData: ITenant = null;
  private refBJ = React.createRef<ITextField>();
  private _labelId: string = getId('dialogLabel');
  private _subTextId: string = getId('subTextLabel');
  private _modalDialogContent: DialogModel = {
    type: DialogType.normal,
    title: UIConstants.MessageBoxTitle.SaveAccess,
    closeButtonAriaLabel: UIConstants.ButtonText.Close,
    subText: UIConstants.Messages.SaveConfirmation,
    okAction: null,
    cancelAction: null,
  };
  private alertMsgCloseBtnRef = React.createRef<IButton>();


  constructor(props: any) {
    super(props);
  }
  public state: AdminBulkRemovalState = {
    userDetails: null,
    showMessage: false,
    removeInProgress: false,
    message: '',
    messageType: MessageBarType.info,
    removeUsersData: '',
    modalDialogContent: this._modalDialogContent,
    hideDialog: true,
    displayReport: false,
    successUsers: '',
    failedUsers: '',
    submittedUsers: '',
    invalidAADUsers: [],
    invalidEUAUsers: [],
    drsUsers: [],
    otherFailedUsers: [],
    justification: '',
    showDialogMessage: false
  }
  public async componentDidMount() {
    let tenantName = (this.props.match.params as any).tenantName;
    this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
    let userAccount = msalAuth.getAccount();
    let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };

    this.setState({ userDetails: user });
    this.onInit(this.tenantData);
  }
  private renderMessage = () => {
    let { message, messageType } = this.state;
    return <div role="alert" aria-label={message} >
      <MessageBar key='Msg' style={{ fontSize: '.875rem' }}
        actions={
          <div>
            <IconButton componentRef={this.alertMsgCloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
          </div>
        }
        messageBarType={messageType}
        isMultiline={false}
      >
        <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
      </MessageBar>
    </div>
  };
  public render(): JSX.Element {
    const { showMessage, removeInProgress, removeUsersData, hideDialog, modalDialogContent, displayReport, successUsers, failedUsers, submittedUsers, invalidAADUsers, invalidEUAUsers, drsUsers, otherFailedUsers, justification, showDialogMessage } = this.state;
    return (<React.Fragment>
      <div id={'div-msg-area'} style={{ height: 50, maxHeight: 50 }}>
        {(removeInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
        {(showMessage) && this.renderMessage()}
      </div>
      <div key="grid-request" className={"ms-Grid"} dir="ltr">
        <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
            <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.BulkRemoval}</h1></Label>
          </div>
          <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg1"}>
            <Label style={{ paddingLeft: 10, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{'   '}</h1></Label>
          </div>
        </div>
        <div className={"ms-Grid-row"} hidden={this.tenantData?.TenantName != UIConstants.Tenant.MSSales} style={{ paddingLeft: 20 }}>
          <Text style={{ fontWeight: "bold" }}> {'To revoke \'User Account - Basic\' access to MS Sales user(s), provide alias(es) below with semicolon(;) seperated format before submitting.'} </Text>
        </div>
        <div key='grid-row-filter' className={"ms-Grid-row"} style={{ paddingLeft: 10 }}>
          <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
            <TextField
              componentRef={this.refBJ} resizable={false} disabled={removeInProgress}
              styles={{ root: { marginTop: 10 } }} label={UIConstants.Users} multiline rows={3} maxLength={1000} value={removeUsersData}
              onChange={this.onremoveUsersDataChange} required placeholder={'alias1; alias2; alias3'}
              description={(1000 - removeUsersData.length) + ' / 1000 Characters left'}
            />
            <div className="float-right">
              <br />
              <div>
                <DefaultButton onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                <span>&nbsp;</span>
                <PrimaryButton onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        {displayReport &&
          <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg6"}>
            <br />
            <div id={'dv-asset-details'}>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label>Total number of Users submitted: </Label></span></td>
                <td key={'td-ast-id-val'} ><span>{submittedUsers.charAt(submittedUsers.length - 1) == ';' ? (submittedUsers.split(';').length - 1).toString() : submittedUsers.split(';').length.toString()}</span></td>
              </tr>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >Successfully processed removals: </Label> </span></td>
                <td key={'td-ast-id-val'} ><span>{(successUsers.split(";").length - 1).toString()}</span></td>
              </tr>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >Failed removals:</Label> </span></td>
                <td key={'td-ast-id-val'} ><span>{(failedUsers.split(";").length - 1).toString()} </span></td>
              </tr>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >User not found in AAD: </Label> </span></td>
                <td key={'td-ast-id-val'} ><span>{invalidAADUsers.length.toString()} </span><br />
                  <span>{invalidAADUsers.map(x => { return <>{x.split(" - ")[0] + ";"}</> })}</span></td>
              </tr>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >User not found in EUA: </Label> </span></td>
                <td key={'td-ast-id-val'} ><>{invalidEUAUsers.length.toString()} </><br />
                  <span>{invalidEUAUsers.map(x => { return <>{x.split(" - ")[0] + ";"}</> })}</span></td>
              </tr>
              <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >Users with DRS Access: </Label> </span></td>
                <td key={'td-ast-id-val'} ><span>{drsUsers.length.toString()} </span><br />
                  <span>{drsUsers.map(x => { return <>{x.split(" - ")[0] + ";"}</> })}</span></td>
              </tr>
              {otherFailedUsers?.length > 0 && <tr key={'tr-ast-id'} >
                <td key={'td-ast-id-key'} ><span style={{ paddingRight: 10 }}><Label >Others: </Label> </span></td>
                <td key={'td-ast-id-val'} ><span >{otherFailedUsers.length.toString()} </span><br />
                  <span>{otherFailedUsers.map(x => { return <>{x.replaceAll("Others=", "")}<br /></> })}</span></td>
              </tr>}
            </div>
          </div>
        }
        <div>
          <ModalDialog hidden={hideDialog}
            onDismiss={this.closeDialog}
            dialogContentProps={modalDialogContent}
            modalProps={{
              titleAriaId: this._labelId,
              subtitleAriaId: this._subTextId,
              isBlocking: false,
              styles: { main: { maxWidth: 450 } },
            }}
            showMessage={showDialogMessage}
            message={this.state.message}
            content={(this.tenantData?.TenantName == UIConstants.Tenant.MSSales) && (modalDialogContent.okButtonText === UIConstants.ButtonText.Submit) &&
              <TextField
                resizable={false} disabled={removeInProgress}
                styles={{ root: { marginTop: 10 } }} label={UIConstants.Reason} multiline rows={3} maxLength={1000}
                value={justification} onChange={this.onBusinessJustificationChange} required
                description={(1000 - justification.length) + ' / 1000 Characters left'}
              />
            }
          >
          </ModalDialog>
        </div>
      </div>
      <br />
    </React.Fragment>
    );
  }
  /**
   * Initial onInit function - first entry point
   */
  private onInit = (tenantData: ITenant) => {
    this.setState({ tenantName: tenantData.TenantName })
    if (tenantData) {

    } else {
      window.location.href = "/";
    }
  };

  private onremoveUsersDataChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue.length <= 1000) {
      this.setState({ removeUsersData: newValue || '', showMessage: false });
    } else {
      this.setState({ showMessage: true, message: UIConstants.Messages.JustificationMaxLength, messageBarType: MessageBarType.warning })
    }
    if (this.tenantData.TenantName == UIConstants.Tenant.MSSales && this.state.message != '') {
      this.setState({ showMessage: true });
    }
  };
  private onBusinessJustificationChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue.length <= 1000) {
      this.setState({ justification: newValue || '', showMessage: false });
    }
  };
  private onResetClick = (): void => {
    this._modalDialogContent = {
      subText: UIConstants.Messages.ResetConfirmation,
      title: UIConstants.MessageBoxTitle.Reset,
      okButtonText: UIConstants.ButtonText.Reset,
      okAction: this.ResetUI,
      cancelAction: this.closeDialog,
      closeMessageAction: this.closeMessageBar,
      type: DialogType.normal,
      closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
    }

    this.setState({ hideDialog: false, modalDialogContent: this._modalDialogContent });
  };
  private closeDialog = (): void => {
    this.setState({ hideDialog: true, justification: '', showDialogMessage: false });
  };
  private closeMessageBar = (): void => {
    this.setState({ showDialogMessage: false });
  };
  private ResetUI = (): void => {
    this.setState({
      hideDialog: true,
      removeUsersData: '',
      showMessage: false,
      message: '',
      submittedUsers: '',
      displayReport: false,
      successUsers: '',
      failedUsers: '',
      justification: '',
      showDialogMessage: false
    });
  };

  private onSubmitClick = async (): Promise<void> => {
    const { removeUsersData } = this.state;
    let users = [];
    removeUsersData.split(";").map(x => x != null && x != "\n" && x.length > 0 && users.push(x))
    this.setState({ removeUsersData: users.join(";") })
    let validationResponse = this.ValidateData();
    if (validationResponse.isValid) {
      this._modalDialogContent = {
        subText: UIConstants.Messages.BulkRemovalConfirmation.replace("#count#", users.join(";").charAt(removeUsersData.length - 1) == ';' ? (users.length - 1).toString() : users.length.toString()),
        title: UIConstants.MessageBoxTitle.BulkRemoval,
        okButtonText: UIConstants.ButtonText.Submit,
        type: DialogType.normal,
        okAction: this.Remove,
        cancelAction: this.closeDialog,
        closeMessageAction: this.closeMessageBar,
        closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
      }
      this.setState({ hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
    } else {
      // Show Error message and skip submission
      this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
    }
  };

  private Remove = (): void => {
    const { removeUsersData, justification } = this.state;
    if (this.tenantData?.TenantName == UIConstants.Tenant.MSSales && (justification.trim().length === 0 || justification.replaceAll(" ", "") == '')) {
      this.setState({
        message: UIConstants.Messages.InvalidReason, showDialogMessage: true, hideDialog: false, showMessage: false
      })
    }
    else {
      let data: BulkRemoval = {
        userDetails: removeUsersData,
        tenantName: (this.props.match.params as any).tenantName,
        businessJustification: justification
      }
      this.setState({ hideDialog: true, removeInProgress: true, showMessage: false, showDialogMessage: false, justification: '' });
      AccessAPI.BulkRemoval(data)
        .then(async res => {
          if (res.status && res.status === 200) {
            let failedusers = res.data.replaceAll("\n", "").split('&')[1].split('FailureRemoval')[1];
            this.setState({
              removeInProgress: false,
              messageType: MessageBarType.success,
              message: UIConstants.Messages.BulkRemovalSuccess,
              showMessage: true,
              removeUsersData: '',
              submittedUsers: data.userDetails,
              displayReport: true,
              successUsers: res.data.replaceAll("\n", "").split('&')[0].split('SuccessfulRemoval')[1],
              failedUsers: failedusers,
              invalidAADUsers: failedusers.split(";").filter(x => x.includes("User not found in AAD")),
              invalidEUAUsers: failedusers.split(";").filter(x => x.includes("User not found in EUA")),
              drsUsers: failedusers.split(";").filter(x => x.includes("DRS Account")),
              otherFailedUsers: failedusers.split(";").filter(x => x.includes("Others="))
            });
          } else if ((res.status && res.status !== 200) || res.message) {
            this.setState({
              removeInProgress: false,
              messageType: MessageBarType.warning,
              message: res.message,
              showMessage: true,
              displayReport: false,
              removeUsersData: removeUsersData,
              successUsers: '',
              failedUsers: '',
              invalidAADUsers: [],
              invalidEUAUsers: [],
              drsUsers: [],
              otherFailedUsers: []
            });
          }
        });
    }
  };
  private ValidateData = (): ValidationResponse => {
    const { removeUsersData, justification } = this.state;
    let response: ValidationResponse = { isValid: true };
    let errorMessage = null;

    if (removeUsersData.trim().length === 0) {
      errorMessage = errorMessage == null ? UIConstants.Users : `${errorMessage}, ${UIConstants.Users}`
    }
    
    if (errorMessage) {
      return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
    }
    else {
      return response; // Validation Success , Allow user to Submit
    }
  };
}
