import React from 'react';
import { RouteProps, Switch, Route } from 'react-router';
import Request from './Request';
import InviteUser from './InviteUser';
import MyAccessRequests from './MyAccessRequests'
import { RouteComponentProps } from 'react-router-dom';
import { SideNav } from './SideNav';
import { Customizer, mergeStyleSets, IStyle } from '@fluentui/react';
import { initializeOCVFeedback, OCVInitialization, navCollapsedWidth } from '@coherence-design-system/controls';
import { CoherenceCustomizations } from '@coherence-design-system/styles';
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import ITenant from '../../shared/models/Tenant.model';
import { Administration } from '../Administration/UserManagement';
import Review from '../Administration/Review';
import _ from "lodash";
import { Utility } from '../../shared/models/Helper';
import AssetView from '../Administration/AssetView';
import RegistrationRequestView from '../Administration/RegistrationRequestView'
import ConsumerAccessRequestView from '../Administration/ConsumerAccessRequestView'
import ConsumerAssetMappingView from '../Administration/ConsumerAssetMappingView';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import { SupplierUserManagement } from '../Administration/SupplierUserManagement';
import { SupplierUserUpdate } from '../Administration/SupplierUserUpdate';
import CreateReview  from '../Administration/CreateReview';
import UpdateRequest from './RequestUpdate';
import MercuryDRSForm from './MercuryDRSForm';
import RequestRenewal from './RequestRenewal';
import { AdminBulkRemoval } from '../Administration/AdminBulkRemoval';
import ScopeApprovers from '../Administration/ScopeApprovers';
import MercuryFAQ from './MercuryFAQ';
import Permissions from '../Administration/Permissions';
import { AddUpdateSupplierUser } from '../Administration/AddUpdateSupplierUser';
const rootDivStyles = {
    position: 'fixed',
    top: 48,
    bottom: 0,
    right: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row'
};
const rootNavStyles = {
    position: 'fixed',
    top: 48,
    bottom: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
};
const _Styles = mergeStyleSets({
    rootDivCollapsed: {
        ...rootDivStyles,
        left: navCollapsedWidth
    } as IStyle,
    rootDivExpand: {
        ...rootDivStyles,
        left: 228
    } as IStyle,
    rootNav: {
        ...rootNavStyles
    } as IStyle,
    contentLeft: {
        width: '300px',
        height: '100%',
        marginTop: '10px'
    } as IStyle,
    contentRight: {
        width: '100%',
        height: '100%',
        marginLeft: '5px'
    } as IStyle,
    toggle: {
        marginBottom: '30px'
    } as IStyle
});

export interface IRequestHomeProps {
    selectedNav: string;

}
export interface IRequestHomeState {
    showPanel: boolean;
    isNavCollapsed: boolean;
    isAdmin: boolean;
    isFDLAuditor: boolean;
    isInvoice: boolean;
    ocvButtonIsFocused: boolean;
    enableDRSform: boolean;
    isMSSalesAdmin: boolean;
}
export default class RequestHome extends React.Component<IRequestHomeProps & RouteProps & RouteComponentProps, IRequestHomeState>  {
    private tenantData: ITenant = null;
    constructor(props: IRequestHomeProps & RouteComponentProps) {
        super(props);


        // initializing the state
        this.state = {
            isNavCollapsed: this.checkWindowState(),
            showPanel: false,
            isAdmin: false,
            isFDLAuditor : false,
            isInvoice: false,
            ocvButtonIsFocused: false,
            enableDRSform: false,
            isMSSalesAdmin: false,
        };

        const ocvInit: OCVInitialization = {
            appId: 2455,
            env: process.env.NODE_ENV === 'production' ? 'Prod' : 'Int',
            scriptUrl: '/officebrowserfeedback.min.js',
            stylesUrl: '/officebrowserfeedback.min.css',
            intlUrl: '/intl/',
            onError: (error: string) => {
                alert('An error has occurred during submission: ' + error);
                this._handleOcvButtonFocusState();
            },
            telemetryGroup: { featureArea: 'Demo site' },
            onDismiss: (submitted: boolean) => {
                if (submitted) {
                    alert('Your feedback has been submitted');
                }
                this._handleOcvButtonFocusState();
            }
        };

        initializeOCVFeedback(ocvInit);
    }
    private _handleOcvButtonFocusState = () => {
        const overflowButton = document.getElementById('coher-header-overflow-button-id');
        if (overflowButton) {
            // set focus back to overflow button 
            overflowButton.focus();
        } else {
            // set focus back to OCV button 
            const ocvButton = document.getElementById('coher-header-ocv-button-id');
            if (ocvButton) {
                ocvButton.focus();
            }
        }

        this.setState({
            ocvButtonIsFocused: false
        });
    };


    public render(): JSX.Element {
        const { url } = this.props.match;
        const { isAdmin, isInvoice, isFDLAuditor, enableDRSform, isMSSalesAdmin } = this.state;

        let urlValues = window.location.pathname.split('/');
        if (urlValues.length < 4) {
            window.location.href = '/'
        } else {

        }
        //setting tenant     
        let tenantName = _.last(urlValues);
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
        let isInvoiceTenant = this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Invoice.toUpperCase();
        let isMercuryTenant = this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Mercury.toUpperCase();
        let isAlfredTenant = this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Alfred.toUpperCase();
        return (
            <Customizer {...CoherenceCustomizations}>
                <div className={_Styles.rootNav} style={{ height: '100%' }}><SideNav onNavCollapsed={this._onNavCollapsed} /></div>

                <section tabIndex={-1} className={this.state.isNavCollapsed ? _Styles.rootDivCollapsed : _Styles.rootDivExpand} >
                    <div style={{ width: '100%', overflowY: 'auto' }} >
                        <Switch>
                            <Route path={`${url}/${UIConstants.RouteConfig.RequestAccess}/:tenantName`} component={Request} />
                            <Route path={`${url}/${UIConstants.RouteConfig.MyAccessRequest}/:tenantName`} component={MyAccessRequests} />
                            {!(tenantName.toUpperCase() == "EBS" && process.env.REACT_APP_ENVIRONMENT == 'dev') && < Route path={`${url}/${UIConstants.RouteConfig.InviteUser}/:tenantName`} component={InviteUser} />}
                            <Route path={`${url}/${UIConstants.RouteConfig.UpdateRequest}/:tenantName`} component={UpdateRequest} />
                            <Route path={`${url}/${UIConstants.RouteConfig.AccessRenewal}/:tenantName`} component={RequestRenewal} />
                            {isMercuryTenant && <Route path={`${url}/${UIConstants.RouteConfig.MercuryFAQ}/:tenantName`} component={MercuryFAQ} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true") && (isMSSalesAdmin || sessionStorage.getItem('mssalesAdmin') === "true") && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/bulkremoval/:tenantName`} component={AdminBulkRemoval} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true" || sessionStorage.getItem('isInvoiceGlobalAdmin') === "true") && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.UserManagement}/:tenantName`} component={isInvoiceTenant ? SupplierUserManagement : Administration} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true") && sessionStorage.getItem(UIConstants.Configuration.DisplayAccessReview) === "true" && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.Review}/:tenantName`} component={Review} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true") && sessionStorage.getItem(UIConstants.Configuration.DisplayAccessReview) === "true" && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.CreateReview}/:tenantName`} component={CreateReview} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true" || sessionStorage.getItem('isInvoiceGlobalAdmin') === "true") && isInvoiceTenant && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.SupplierUserUpdate}/:tenantName`} component={SupplierUserUpdate} />}
                            {(sessionStorage.getItem('isInvoiceGlobalAdmin') === "true") && isInvoiceTenant && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.AddUpdateSupplierUser}/:tenantName`} component={AddUpdateSupplierUser} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true" || sessionStorage.getItem('isInvoiceGlobalAdmin') === "true") && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ApprovalPolicy}/:tenantName`} component={ScopeApprovers} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true") && (this.tenantData?.isExternalAADTenant) && <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.Permissions}/:tenantName`} component={Permissions} />}
                            {(isAdmin || sessionStorage.getItem('isAdmin') === "true") ? sessionStorage.getItem('isFDL') === "true" &&
                                <><Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.AssetView}/:tenantName`} component={AssetView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.RegistrationRequestView}/:tenantName`} component={RegistrationRequestView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ConsumerAccessRequestView}/:tenantName`} component={ConsumerAccessRequestView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Administration}/${UIConstants.RouteConfig.ConsumerAssetMappingView}/:tenantName`} component={ConsumerAssetMappingView} />
                                </>
                                : (isFDLAuditor || sessionStorage.getItem('showReports') === "true") && sessionStorage.getItem('isFDL') === "true" &&
                                <>
                                    <Route path={`${url}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.AssetView}/:tenantName`} component={AssetView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.RegistrationRequestView}/:tenantName`} component={RegistrationRequestView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.ConsumerAccessRequestView}/:tenantName`} component={ConsumerAccessRequestView} />
                                    <Route path={`${url}/${UIConstants.RouteConfig.Reports}/${UIConstants.RouteConfig.ConsumerAssetMappingView}/:tenantName`} component={ConsumerAssetMappingView} />
                                </>
                            }
                            {(enableDRSform || sessionStorage.getItem('enableDRSForm') == "true") && <Route path={`${url}/${UIConstants.RouteConfig.DRSForm}/:tenantName`} component={MercuryDRSForm} />}
                        </Switch>
                    </div>
                </section>

            </Customizer>
        );
    }

    async componentDidMount() {
        window.addEventListener("resize", this.onBrowserResize.bind(this));
        this.onBrowserResize();
        let urlValues = window.location.pathname.split('/');
        if (urlValues.length < 4) {
            window.location.href = '/'
        } else {

        }
        //setting tenant     
        let tenantName = _.last(urlValues);
        this.tenantData = Utility.GetTenantData().filter(x => x.TenantName.toLowerCase() === tenantName.toLowerCase())[0];
        sessionStorage.setItem("isInvoice", "false");
        sessionStorage.setItem("mssalesAdmin", "false");
        sessionStorage.setItem('isAdmin', "false");
        sessionStorage.setItem('isInvoiceGlobalAdmin', "false");
        this.setState({ isMSSalesAdmin: false, isAdmin: false });
        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MSApprovalLink)
            .then(response => {
                sessionStorage.setItem(UIConstants.Configuration.MSApprovalLink, response.toString());
            });
        AccessAPI.CheckDisplayReviewPage(this.tenantData.TenantId)
            .then(response => {
                sessionStorage.setItem(UIConstants.Configuration.DisplayAccessReview, response?.toString());
            });
        sessionStorage.setItem("enableDRSForm", "false");
        if (this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.MSSales.toUpperCase()) {
            let adminAccess = AccessAPI.CheckUserIsAdmin(this.tenantData.TenantId);
            let usermanagemantAccess = AccessAPI.CheckUserhasUserManagementAccess(this.tenantData.TenantId);
            Promise.all([adminAccess, usermanagemantAccess]).then((responses) => {
                sessionStorage.setItem("mssalesAdmin", responses[0].toString());
                sessionStorage.setItem('isAdmin', responses[1].toString());
                this.setState({ isMSSalesAdmin: responses[0], isAdmin: responses[1] });
            });
        }
        else if (this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Invoice.toUpperCase()) {
          let adminAccess = AccessAPI.CheckUserIsAdmin(this.tenantData.TenantId);
          let usermanagemantAccess = AccessAPI.CheckUserhasUserManagementAccess(this.tenantData.TenantId);
          Promise.all([adminAccess, usermanagemantAccess]).then((responses) => {
            sessionStorage.setItem("isInvoice", "true");
            sessionStorage.setItem('isInvoiceGlobalAdmin', responses[0].toString());
            sessionStorage.setItem('isAdmin', responses[1].toString());
            this.setState({ isAdmin: responses[1], isInvoice: this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Invoice.toUpperCase() });
          });
        }
        else {
            AccessAPI.CheckUserhasUserManagementAccess(this.tenantData.TenantId).then(response => {
                if (this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.FDL) {
                    if (!response) {
                        AccessAPI.CheckUserIsAuditor(this.tenantData.TenantId).then(fdlAuditorRes => {
                            sessionStorage.setItem('showReports', fdlAuditorRes.toString());
                            this.setState({ isFDLAuditor: fdlAuditorRes, isAdmin: false });
                        })
                    }
                    else {
                        sessionStorage.setItem('showReports', "false");
                    }
                    ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.EnvironmentConfig)// Call API to Get FDL EnvironmentConfig
                        .then(response => {
                            sessionStorage.setItem(UIConstants.Configuration.EnvironmentConfig, response.toString());
                        });
                }
                sessionStorage.setItem('isAdmin', response.toString());
                this.setState({ isAdmin: response, isInvoice: this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Invoice.toUpperCase() });
            });
        }
        if (this.tenantData.TenantName == UIConstants.Tenant.Mercury) {
            ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MercuryConfig).then(response => {
                let approvers = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
                if (approvers?.EnableDRSForm) {
                    sessionStorage.setItem("enableDRSForm", "true");
                    this.setState({ enableDRSform: true });
                }
                else {
                    sessionStorage.setItem("enableDRSForm", "false");
                    this.setState({ enableDRSform: false });
                }

                if(approvers?.EnableFAQPage) {
                    sessionStorage.setItem('EnableFAQPage', 'true');
                }
                else{
                    sessionStorage.setItem('EnableFAQPage', 'false');
                }
            });
        }
    }
    private onBrowserResize() {
        this.setState({ isNavCollapsed: this.checkWindowState() });
    }
    private checkWindowState() {
        let widnowSize = window.innerWidth;
        let isNavCollapsed = false;
        if (widnowSize < 900) {
            isNavCollapsed = true;
        }

        return isNavCollapsed;
    }
    private _onNavCollapsed = (isCollapsed: boolean) => {
        this.setState({ isNavCollapsed: isCollapsed });
    }
}
