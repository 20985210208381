import React from 'react';
import { Spinner, SpinnerSize, Text, MessageBarType, MessageBar, Announced, Label, mergeStyleSets, FontSizes, ActivityItem, Icon } from '@fluentui/react';
import _ from "lodash";
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import { ApproverDetailsResponse } from '../../shared/models/ApproverDetails.model';
import { Utility } from '../../shared/models/Helper';

interface AccessHistoryStatusProps {
  requestItem: any;
}
interface AccessHistoryStatusState {
  AppproverDetails: ApproverDetailsResponse
}
const classNames = mergeStyleSets({
  activityRoot: {
    marginTop: "20px"
  },
  nameText: {
    fontWeight: "bold",
    fontSize: FontSizes.medium
  }, actionText: {
    fontSize: FontSizes.medium
  }, actionByText: {
    fontWeight: "bold",
    fontSize: FontSizes.medium,
    color: '#0064BF'
  },
  lblText: {
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    // -webkit-font-smoothing: antialiased;
    fontSize: 14,
    fontWeight: 600,
    color: 'rgb(23, 23, 23)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    margin: 0,
    display: 'block',
    padding: '5px 0px',
    overflowWrap: 'break-word',
  }
});

export default class AccessHistoryStatus extends React.Component<AccessHistoryStatusProps, AccessHistoryStatusState> {

    private tenantName: string;
  constructor(props) {
    super(props);
    this.state = {
      AppproverDetails: null
    };
  }
  public componentDidMount() {
    this.ensureDataFetched();
  }
  private ensureDataFetched() {
      const { requestItem } = this.props;
      let urlPathName = window.location.pathname
      let urlValues = urlPathName.split('/');
      this.tenantName = _.last(urlValues).toUpperCase();

      if (requestItem.requestType.toUpperCase() == 'REMOVE' || requestItem.requestType.toUpperCase() === 'BULK REMOVE' || requestItem.requestType.toUpperCase() === 'DEPROVISION' || requestItem.requestType.toUpperCase() == 'AUTO EXPIRE') {
      this.setState({ AppproverDetails: { Data: null, IsSuccess: true, IsDataLoaded: true } });
    } else {
      if (requestItem.status === 'InProgress') {
        AccessAPI.getApprovalStatusDetails(requestItem.requestId).then(res => {
          this.setState({ AppproverDetails: res });
        });
      } else {
        AccessAPI.GetProvisionedRequestStatus(requestItem.requestId).then(res => {
          this.setState({ AppproverDetails: res });
        });
      }
    }
  }
  public render() {
    const { AppproverDetails } = this.state;
    const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
    const { migrationData, requestData } = Data || {};
    let ApprovalRequestDetails = requestData?.approvalRequest;
    if (AppproverDetails?.IsDataLoaded) {
      return <React.Fragment>
        <Announced id={'myaccesststusloadend' + Date.now()} message={'Loaded'} />
        <div role="alert" style={{ width: 'auto', maxWidth : 350 , padding: 10, wordWrap: 'break-word' }} >
          {
            (AppproverDetails?.IsDataLoaded) ?
              (AppproverDetails?.IsSuccess) ? <div>
                {this.RenderApprovalStatus()}
              </div> : <div>  <Text styles={{ root: { fontWeight: 'bold' } }}>{UIConstants.Messages.RequestStatusNotFound}</Text></div> :
              <div><Spinner size={SpinnerSize.xSmall} ariaLabel="loading" label="loading..." ariaLive="assertive" labelPosition="bottom" /></div>
          }
        </div>
      </React.Fragment>
    } else {
      return <div style={{ height: 100, width: 200 }} >
        <Announced id={'myaccesststusloadstart' + Date.now()} message={'Loading'} />
        <Spinner size={SpinnerSize.xSmall} label="loading..." ariaLive="assertive" labelPosition="bottom" />
      </div>
    }
  };
  private renderMessage = (message: string, messageType: MessageBarType) => {
    return <MessageBar key='FetchMsg' messageBarType={messageType} isMultiline={false}> {message} </MessageBar>
  };
  private renderRequestHistory = () => {
    const { requestItem } = this.props;
    const { AppproverDetails } = this.state;
    let { migrationData, requestData } = AppproverDetails?.Data || {};
    let ApprovalRequestDetails = requestData?.approvalRequest;

    let Histogram = [];
      if (requestItem.requestType.toUpperCase() === 'REMOVE' || requestItem.requestType.toUpperCase() === 'BULK REMOVE' || requestItem.requestType.toUpperCase() === 'DEPROVISION' || requestItem.requestType.toUpperCase() === 'AUTO EXPIRE') {
      Histogram = [{
        key: 'Item-root-remove',
        // activityDescription: [
        //   <span key={'item-level-remove'} className={classNames.nameText}>
        //     Removed </span>,],
        activityIcon: <Icon iconName={'Trash'} />,
          timeStamp: [
              (requestItem.requestType.toUpperCase() === 'BULK REMOVE' || requestItem.requestedBy?.split('@')[0] != requestItem.user.split('@')[0]) ? <>
                  <span key={'item--remove'} className={classNames.actionText}>
                      { 'Removed By '}
                  </span>
                  <span key={'item--remove-by'} className={classNames.actionByText}>
                      {requestItem.requestedBy.split('@')[0] }
                  </span>
                  <span key={'item--remove-timestamp'} className={classNames.actionText}>
                      {' On - ' + new Date(requestItem.completedDate).toLocaleString()}
                  </span>
              </> :
            <span key={'item--remove-timestamp'} className={classNames.actionText}>
                { 'Removed On - ' + new Date(requestItem.completedDate).toLocaleString()}
            </span>
           ]
      }]
    } else {
      Histogram = ApprovalRequestDetails.approvalHierarchy.map(approvalItem => {
        return {
          key: 'item-root-' + approvalItem.Level,
          activityDescription: [
            <span key={'item-level-' + approvalItem.Level} className={classNames.nameText}>
              Level  {approvalItem.Level}
            </span>,
            <span key={'item-status-' + approvalItem.Level} className={classNames.actionText}>
              {approvalItem.IsApproved === null ? ' Pending with ' : approvalItem.IsApproved ? ' Approved by' : ' Rejected by'} </span>,
            <span key={approvalItem.Level} className={classNames.actionByText}>
              {approvalItem.IsApproved === null ? approvalItem.AssignedTo.split(';').join('; ') : approvalItem.ActionByAlias}
            </span>,
          ],
          activityIcon: <Icon style={{ color: approvalItem.IsApproved === null ? '#0078d4' : approvalItem.IsApproved ? 'green' : 'red' }}
            iconName={approvalItem.IsApproved === null ? 'SyncStatusSolid' : approvalItem.IsApproved ? 'SkypeCircleCheck' : 'SkypeCircleMinus'} />,
          comments: [
            <span key={'item-comments-' + approvalItem.Level}>{approvalItem.ApproverComments}</span>
          ],
          timeStamp:
            [
              <span key={'item-timestamp-' + approvalItem.Level} className={classNames.actionText}>{approvalItem.ApprovalActionDate ?
                new Date(approvalItem.ApprovalActionDate).toLocaleString() : 'Submitted On - ' + new Date(approvalItem.SubmittedDate).toLocaleString()}
              </span>
            ]
        }
      })
    }
    return Histogram;
  };
  private RenderApprovalStatus = (): JSX.Element => {
    const { requestItem } = this.props;
    const { AppproverDetails } = this.state;

    let ApprovalResponse = AppproverDetails;

    const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
    const { migrationData, requestData } = Data || {};

    let ApprovalRequestDetails = requestData?.approvalRequest;

      if (requestItem.requestType.toUpperCase() === UIConstants.RequestType.Remove.toUpperCase() || requestItem.requestType.toUpperCase() === 'BULK REMOVE' || requestItem.requestType.toUpperCase() === 'DEPROVISION' || requestItem.requestType.toUpperCase() === 'AUTO EXPIRE') {
      return <div>{this.renderRequestHistory().map(
        (item: { key: string | number }) => (<ActivityItem {...item} key={item.key} className={classNames.activityRoot} />)
      )}</div>
    }
    else if (ApprovalRequestDetails) {
      return <div>
        <Label>Approval Stage : {ApprovalRequestDetails.currentLevel} / {ApprovalRequestDetails.approvalLevels}</Label>
        {this.renderRequestHistory().map(
          (item: { key: string | number }) => (
            <ActivityItem
              {...item}
              key={item.key}
              className={classNames.activityRoot}
            />
          )
        )}
      </div>
    } else {
      if (ApprovalResponse?.IsDataLoaded) {
        if (ApprovalResponse?.IsSuccess && migrationData) {
          return <>
            <Label>Completed On : <span key={'item-timestamp-mig'} className={classNames.actionText}>{migrationData?.requestedDate ?
              new Date(migrationData?.requestedDate).toLocaleString() : 'N/A'}
            </span> </Label>
          </>
        }
        else if (requestItem.role == UIConstants.PermissionTypes.MercuryInforma && ApprovalResponse?.IsSuccess && ApprovalResponse.Data.requestData.approvalRequest == null) {
            return <div>{this.renderMessage('This role access was provided along with Basic/P&L roles.', MessageBarType.info)}</div>
          }
        else if (this.tenantName.toLowerCase() == UIConstants.Tenant.Mercury.toLowerCase() && requestItem.requestedBy.split('@')[0] != requestItem.user.split('@')[0]) {
            return <div>{this.renderMessage('Auto approved for Executive', MessageBarType.info)}</div>
        }
        else {
          return <div>{this.renderMessage(UIConstants.Messages.RequestStatusNotFound, MessageBarType.error)}</div>
        }
      } else {
        return <Spinner size={SpinnerSize.xSmall} label="loading..." ariaLive="assertive" labelPosition="bottom" />
      }
    }
  };
}