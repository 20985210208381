import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { Label, MessageBarType, ProgressIndicator, MessageBar, IconButton, ChoiceGroup, IChoiceGroupOption, DefaultButton, PrimaryButton, DialogType, getId, TextField, IButton } from '@fluentui/react';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerType, PeoplePickerSelectionMode, PeoplePickerContentType } from '../../shared/models/PeoplePicker.enum';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import { InviteUserModel } from '../../shared/models/InviteUser.model';

import { DialogModel } from '../../shared/models/Dialog.model';
import { ModalDialog } from '../ModalDialog';
import ITenant from '../../shared/models/Tenant.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
enum InviteType {
    user = 'user', group = 'group'
}
export interface IInviteUserProps {
}

export interface InviteUserState {
    items: IUser[];
    saveInProgress: boolean;
    showMessage: boolean;
    autoHideMessage: boolean;
    message: string;
    messageType: MessageBarType;
    defaultSelectedItems: [];
    selectedUser: [];
    selectedgroup : string;
    hideDialog: boolean;
    modalDialogContent: any;
    pickerCtrlKey: number;
    inviteType: InviteType;
    setFocusOnPicker : boolean;
}

type InviteUserProps = IInviteUserProps & RouteComponentProps<{}>;


export default class InviteUser extends React.Component<InviteUserProps>  {
    private _allItems: IUser[] = [];
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    private options: IChoiceGroupOption[] = [
        { key: 'user', text: 'User', iconProps: { iconName: 'Contact' } },
        { key: 'group', text: 'Group', iconProps: { iconName: 'Group' } , disabled : true },
    ];
    private tenantData: ITenant = null;
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName; 
        tenantName = tenantName.toUpperCase();
        let TenantData = Utility.GetTenantData();
        this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
        this.setState({ userDetails: user });
    }

    constructor(props: any) {
        super(props);
    }
    public state: InviteUserState = {
        items: this._allItems,
        saveInProgress: false,
        showMessage: false,
        autoHideMessage: false,
        message: null,
        messageType: MessageBarType.info,
        selectedUser: [],
        selectedgroup : '',
        defaultSelectedItems: [],
        hideDialog: true,
        modalDialogContent: this._modalDialogContent,
        pickerCtrlKey: 0,
        inviteType: InviteType.user,
        setFocusOnPicker : false,
    }

    public render(): JSX.Element {
        const { saveInProgress, showMessage, hideDialog, modalDialogContent, pickerCtrlKey, inviteType, selectedgroup,setFocusOnPicker } = this.state;
        return (<React.Fragment>
            <div id={'div-msg-area'} style={{ height: 25 }}>
                {(showMessage) && this.renderMessage()}
                {(saveInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-invite-section'} style={{ padding: '1%', paddingTop: '2%' }} >
                <div key="grid-invite" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm8 ms-md8 ms-lg10"}>
                            <Label style={{ paddingLeft: 3, fontSize: 20 }} ><h1 className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}>{UIConstants.PageTitle.InviteUser}</h1></Label>
                        </div>
                    </div>
                    <div key='grid-row-invite' className={"ms-Grid-row"} >
                        <ChoiceGroup style={{ marginLeft: '0.5%' }} onChange={this._onRadioBtnChange} defaultSelectedKey={inviteType} options={this.options} />
                        {(inviteType === InviteType.user) ?
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} >
                                <Label required={true} >{UIConstants.Labletext.Users}</Label>
                                <div>
                                    <PeoplePicker
                                        key={pickerCtrlKey}
                                        type={PeoplePickerType.Normal}
                                        contentType={PeoplePickerContentType.User}
                                        selectionMode={PeoplePickerSelectionMode.Multiple}
                                        onchange={(items) => { this._onItemsChange(items) }}
                                        defaultSelectedItems={[]}
                                        {...this.props}
                                        setFocusOnPicker={setFocusOnPicker}
                                        hideCEO={true}
                                    ></PeoplePicker>
                                </div>
                            </div> : <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                <TextField required={true} label={UIConstants.Labletext.Groups} description="Please use semicolon to seperate multiple groups" value={selectedgroup} onChange={this.OnGroupTextChange} />
                            </div>
                        }
                    </div>
                    <div key='grid-row-action' className={"ms-Grid-row"}>
                        <br aria-hidden={true} />
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} >
                            <div className="float-right">
                                <DefaultButton role="button" tabIndex={0} onClick={this.onResetClick} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                <span>&nbsp;</span>
                                <PrimaryButton role="button" tabIndex={0} onClick={this.onInviteClick} >{UIConstants.ButtonText.SendInvite} </PrimaryButton>
                            </div>
                        </div>
                        <div>
                            <ModalDialog hidden={hideDialog}
                                onDismiss={this.closeDialog}
                                dialogContentProps={modalDialogContent}
                                modalProps={{
                                    titleAriaId: this._labelId,
                                    subtitleAriaId: this._subTextId,
                                    isBlocking: false,
                                    styles: { main: { maxWidth: 450 } },
                                }}
                            >
                            </ModalDialog>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
        );
    }
    private _onRadioBtnChange = (ev, options) => {
        this.setState({ inviteType: options.key , selectedgroup : '' , showMessage : false });
    }
    private OnGroupTextChange = (ev, newValue) => {
        this.setState({ selectedgroup: newValue , showMessage : false});
    }
    private renderMessage = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds

            setTimeout(() => { 
                this.alertMsgCloseBtnRef.current.focus();
             }, 100) // set the focus to close button
        return <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
            actions={
                <div role="alert" aria-label={message} >
                    <IconButton componentRef={this.alertMsgCloseBtnRef} iconProps={{ iconName: 'Clear' }} 
                    title="Close" ariaLabel="close" onClick={() => { this.setState({ showMessage: false , setFocusOnPicker : true  }) }} />
                </div>
            }
            messageBarType={messageType}
            isMultiline={false}
        >
            {message}
        </MessageBar>
    };
    private _onItemsChange(items: any) {
        if (items && items.length > 0) {
            let userProperties = items.map(x => x.tertiaryText);
            let list = [];
            for (const userItem of userProperties) {
                let parsedData = JSON.parse(userItem);
                list.push(parsedData.email);
            }
            this.setState({ selectedUser: list, defaultSelectedItems: items, showMessage: false });
        }
    }

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private onResetClick = (): void => {
        const { pickerCtrlKey, inviteType } = this.state;
        if (inviteType === InviteType.user) {
            let newkey = pickerCtrlKey + 1;
            this.setState({
                hideDialog: true,
                showMessage: false,
                pickerCtrlKey: newkey,
                selectedUser: [],
                setFocusOnPicker : true,
            });
        } else {
            this.setState({
                hideDialog: true,
                showMessage: false,
                selectedgroup: ''
            });
        }
    };
    private onInviteClick = async (): Promise<void> => {
        let validationResponse = this.ValidateData();
        if (validationResponse.isValid) {
            this._modalDialogContent = {
                subText: UIConstants.Messages.InviteConfirmation,
                title: UIConstants.MessageBoxTitle.InviteUser,
                okButtonText: UIConstants.ButtonText.Save,
                type: DialogType.normal,
                okAction: this.InviteUser,
                cancelAction: this.closeDialog,
                closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
            }
            this.setState({ setFocusOnPicker : false, hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
        } else {
            // Show Error message and skip submission
            this.setState({ setFocusOnPicker : false, showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
        }
    };
    private InviteUser = (): void => {
        const { selectedUser, pickerCtrlKey , selectedgroup , inviteType} = this.state;
        let t = this;
        let inviteUserData: InviteUserModel = {
            tenantId: this.tenantData.TenantId,
            tenantName: this.tenantData.TenantName,
            recipients:  inviteType === InviteType.user ? selectedUser.join(';') : selectedgroup,
            inviteType : inviteType
        };
        this.setState({ hideDialog: true, saveInProgress: true ,showMessage : false});
        AccessAPI.inviteUsersAsync(inviteUserData).then((inviteResponse) => {
            if (inviteResponse.status && inviteResponse.status === 200) {
                return inviteResponse as Promise<any>;
            } else {
                return inviteResponse as Promise<string>; // in case of error
            }
        }).then(async res => {
            if ((res.status && res.status !== 200) || res.message) {// Error
                let msg = await res.text();
                t.setState({
                    saveInProgress: false,
                    messageType: MessageBarType.warning,
                    message: msg,
                    showMessage: true,
                    autoHideMessage: false,
                });
            } else {
                let newkey = pickerCtrlKey + 1;
                t.setState({
                    saveInProgress: false,
                    messageType: MessageBarType.success,
                    message: UIConstants.Messages.InviteSuccess,
                    showMessage: true,
                    autoHideMessage: false,
                    pickerCtrlKey: newkey,
                    selectedUser: [],
                    selectedgroup : ''
                });
            }
        });
    };

    /**
   * Helper function to validate the data to be submitted
   */
    private ValidateData = (): ValidationResponse => {
        const { selectedUser , selectedgroup , inviteType } = this.state;
        let response: ValidationResponse = { isValid: true };
        if (inviteType === InviteType.user) {
            if (selectedUser.length > 0) {
                response = { isValid: true };// Validation Success , Allow user to Submit
            } else {
                response = { isValid: false, validationMessage: UIConstants.Messages.MandatoryField };
            }
        } else {
            if (selectedgroup && selectedgroup.trim().length > 0) {
                response = { isValid: true };// Validation Success , Allow user to Submit
            } else {
                response = { isValid: false, validationMessage: UIConstants.Messages.MandatoryField };
            }
        }
        return response;
    };

}