import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {App} from './App';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { BrowserRouter } from 'react-router-dom';

initializeIcons();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
   ,
    document.getElementById('root'));

