import React from 'react';
import { Spinner, SpinnerSize, Text, MessageBarType, MessageBar, Announced } from '@fluentui/react';
import _ from "lodash";
import { UIConstants } from '../../shared/models/Constants';
import AccessAPI from '../../store/AccessAPI';
import { ApproverDetailsResponse } from '../../shared/models/ApproverDetails.model';

interface MyAccessStatusProps {
    requestItem: any;
}
interface MyAccessStatusState {
    AppproverDetails : ApproverDetailsResponse
}
export default class MyAccessStatus extends React.Component<MyAccessStatusProps, MyAccessStatusState> {
    constructor(props) {
        super(props);
        this.state = {
            AppproverDetails : null
        };
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    private ensureDataFetched() {
        const { requestItem } = this.props;
        if (requestItem)
            AccessAPI.getApprovalStatusDetails(requestItem.requestId).then(res=>{
                this.setState({ AppproverDetails: res });
            })
    }
    get(assignedTo: any, ActionbyAlias: any, currentLevel: any) {
        if (ActionbyAlias == null) {
            return assignedTo[currentLevel];
        }
        if (assignedTo[currentLevel] == null) {
            return "-";
        }
        var assignedTo = assignedTo[currentLevel].split(";");
        let uniqueAlias = new Set();
        assignedTo.forEach(x => { uniqueAlias.add(x); });
        var pendingAlias = '';
        uniqueAlias.forEach(element => {
            ActionbyAlias.forEach(item => {
                if (element == item) {
                    uniqueAlias.delete(element);
                }
            });
            if (uniqueAlias.has(element))
                pendingAlias = pendingAlias + element + ";"
        });      
        return pendingAlias.split(';').filter((alias, index) => pendingAlias.split(';').indexOf(alias) === index).join(';');
    }
    public render() {
        const { AppproverDetails } = this.state;
        const { IsDataLoaded, IsSuccess, Data } = AppproverDetails || {};
        const { migrationData , requestData } = Data || {};
        let ApprovalRequestDetails = requestData?.approvalRequest;
        var approvedby= [];
        var assignedTo = [];
        var Notes = [];
        if (AppproverDetails?.IsDataLoaded) {
            if (AppproverDetails?.IsSuccess && requestData?.requestId) {
                ApprovalRequestDetails.approvalHierarchy?.forEach(approvalItem => {
                    if (assignedTo[approvalItem.Level] != null) {
                        if (approvalItem.ActionByAlias == null) {
                            assignedTo[approvalItem.Level] += ";" + approvalItem.AssignedTo;
                        }
                    }
                    else {
                        if (approvalItem.ActionByAlias == null) {
                            assignedTo[approvalItem.Level] = approvalItem.AssignedTo;
                        }
                    }
                    if (ApprovalRequestDetails?.currentLevel == approvalItem.Level) {
                        let actionByAlias = approvalItem.ActionByAlias
                        if (actionByAlias != null && !approvedby.includes(actionByAlias)) {
                            approvedby.push(actionByAlias);
                        }
                        if (approvalItem.Notes) {
                            Notes.push(approvalItem.Notes)
                        }
                    }
                })
            }
            return <React.Fragment>
                <Announced id={'myaccesststusloadend' + Date.now()} message={'Loaded'} />
                <div role="alert" style={{ height: 100, width: 200, padding: 10, wordWrap: 'break-word' }} >
                    {
                        (AppproverDetails?.IsDataLoaded) ?
                            (AppproverDetails?.IsSuccess && requestData?.requestId && ApprovalRequestDetails.approvalHierarchy != null && ApprovalRequestDetails.approvalHierarchy.length>0) ? (approvedby.length > 0) ? <div>
                                <Text styles={{ root: { fontWeight: 'bold' } }} >Approval Stage : </Text><Text> {ApprovalRequestDetails?.currentLevel} / {ApprovalRequestDetails?.approvalLevels}</Text>
                                <br />
                                <Text styles={{ root: { fontWeight: 'bold' } }}>Pending with : </Text><Text >  {this.get(assignedTo, approvedby, ApprovalRequestDetails?.currentLevel)}</Text>
                                <br />
                                <Text styles={{ root: { fontWeight: 'bold' } }}>Action by : </Text><Text>  {approvedby.toString().split(",").join("; ") || "-"}</Text>
                            </div> :
                                <div>
                                    <Text styles={{ root: { fontWeight: 'bold' } }} >Approval Stage : </Text><Text> {ApprovalRequestDetails?.currentLevel} / {ApprovalRequestDetails?.approvalLevels}</Text>
                                    <br />
                                    <Text styles={{ root: { fontWeight: 'bold' } }}>Pending with : </Text><Text >  {this.get(assignedTo, approvedby, ApprovalRequestDetails?.currentLevel)}</Text>
                                    <br />
                                    <Text styles={{ root: { fontWeight: 'bold' } }}>Notes : </Text><Text> {Notes} </Text>
                                </div>: <div>  <Text styles={{ root: { fontWeight: 'bold' } }}>{UIConstants.Messages.RequestStatusNotFound}</Text></div> :
                            <div><Spinner size={SpinnerSize.xSmall} ariaLabel="loading" label="loading..." ariaLive="assertive" labelPosition="bottom" /></div>
                    }
                </div>
            </React.Fragment>
        } else {
            return <div style={{ height: 100, width: 200 }} >
                <Announced id={'myaccesststusloadstart' + Date.now()} message={'Loading'} />
                <Spinner size={SpinnerSize.xSmall} label="loading..." ariaLive="assertive" labelPosition="bottom" />
            </div>
        }

    };
    private renderMessage = (message: string, messageType: MessageBarType) => {
        return <MessageBar key='FetchMsg' messageBarType={messageType} isMultiline={false}> {message} </MessageBar>
    };

}